import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
import DashboardScreen from '../features/app/DashboardScreen';
import AppLayout from './AppLayout';
import ChangePassword from '../features/app/ChangePassword';
import Attendance from '../features/app/attendance/Attendance';
import AttendanceForm from '../features/app/attendance/AttendanceForm';
import ShortLeaveTable from '../features/app/Short Leave/ShortLeaveTable';
import AddShortLeaveForm from '../features/app/Short Leave/AddShortLeaveForm';
import CompOffTable from '../features/app/CompensoryOff/CompOfftable';
import AddCompensatory from '../features/app/CompensoryOff/AddCompensatory';
import AttendanceRegularisationScreen from '../features/app/Attendance Management/AttendanceRegularisationScreen';
import AttendanceReportScreen from '../features/app/Attendance Management/AttendanceReportScreen';
import LeaveEnchasmentScreen from '../features/app/Leave Encashment/LeaveEncashmentScreen';
import LeaveTable from '../features/app/Leave/LeaveScreen';
import AddLeave from '../features/app/Leave/AddLeave';
import LeaveCancellation from '../features/app/Leave/LeaveCancellation';
import LeaveHistory from '../features/app/Leave/LeaveHistory';
import LeaveApprovalArchivedDetails from '../features/app/Leave/LeaveApprovalArchivedDetails';
import LeaveEncashmentForm from '../features/app/Leave Encashment/LeaveEncashmentForm';

import ManageTravelTable from '../features/app/Travel Management/ManageTravelTable';
import TravelRequisitionTable from '../features/app/Travel Management/TravelRequisitionTable';
import LoyalConveyanceTable from '../features/app/Travel Management/LoyalConveyanceTable';
import AddLoyalConveyance from '../features/app/Travel Management/AddLoyalConveyance';
import TravelExpensesArchiveTable from '../features/app/Travel Management/TravelExpensesArchivetable';
import TravelExpensesCancellationTable from '../features/app/Travel Management/TravelExpensesCancellationTable';
import ArchiveRequestTotalTable from '../features/app/Travel Management/ArchiveRequestTotalTable';
import TravelRequisitionForm from '../features/app/Travel Management/TravelRequisitionForm';
import TravelRequisitionArchivedDetails from '../features/app/Travel Management/TravelRequisitionArchivedDetails';
import TravelRequisitionCancellationDetails from '../features/app/Travel Management/TravelRequisitionCancellationDetails';
import TravelExpensesClaimDetails from '../features/app/Travel Management/TravelExpensesClaimDetails';
import TravelExpensesClaimForm from '../features/app/Travel Management/TravelExpensesClaimForm';
import ClaimForm from '../features/app/Travel Management/ClaimForm';
import ProfileScreen from '../features/app/ProfileScreen/ProfileScreen';
const AppRoutes = ({ isLogged }: any) => {
	console.log('isLogged', isLogged, localStorage.getItem('user'));
	const user = localStorage.getItem('user');

	return (
		<Routes>
			{user ? (
				<Route path="/" element={<AppLayout />}>
					<Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
					<Route
						path={APP_ROUTES.ATTENDANCE_REGULARISATION}
						element={<Attendance />}
					/>
					<Route
						path={APP_ROUTES.ATTENDANCE_REPORT}
						element={<AttendanceReportScreen />}
					/>
					<Route
						path={APP_ROUTES.CHANGE_PASSWORD}
						element={<ChangePassword />}
					/>
					<Route path={APP_ROUTES.ATTENDANCE} element={<Attendance />} />
					<Route
						path={APP_ROUTES.ATTENDANCE_FORM}
						element={<AttendanceForm />}
					/>
					<Route path={APP_ROUTES.SHORT_LEAVE} element={<ShortLeaveTable />} />
					<Route
						path={APP_ROUTES.ADD_SHORT_LEAVE}
						element={<AddShortLeaveForm />}
					/>{' '}
					<Route
						path={APP_ROUTES.COMPENSATORY_LEAVE}
						element={<CompOffTable />}
					/>{' '}
					<Route
						path={APP_ROUTES.ADD_COMPENSATORY_LEAVE}
						element={<AddCompensatory />}
					/>{' '}
					<Route path={APP_ROUTES.LEAVE} element={<LeaveTable />} />
					<Route
						path={APP_ROUTES.LEAVE_ENCASHMENT}
						element={<LeaveEnchasmentScreen />}
					/>
					<Route
						path={APP_ROUTES.ATTENDANCE_ARCHIVED_REPORT}
						element={<AttendanceRegularisationScreen />}
					/>
					<Route path={APP_ROUTES.ADD_LEAVE} element={<AddLeave />} />{' '}
					<Route
						path={APP_ROUTES.LEAVE_CANCELLATION}
						element={<LeaveCancellation />}
					/>
					<Route path={APP_ROUTES.LEAVE_HISTORY} element={<LeaveHistory />} />{' '}
					<Route
						path={APP_ROUTES.LEAVE_ARCHIVED}
						element={<LeaveApprovalArchivedDetails />}
					/>{' '}
					<Route
						path={APP_ROUTES.MANAGE_TRAVEL}
						element={<ManageTravelTable />}
					/>
					<Route
						path={APP_ROUTES.TRAVEL_REQUISITION}
						element={<TravelRequisitionTable />}
					/>
					<Route
						path={APP_ROUTES.TRAVEL_REQUISITION_FORM}
						element={<TravelRequisitionForm />}
					/>
					<Route
						path={APP_ROUTES.LEAVE_ENCASHMENT_FORM}
						element={<LeaveEncashmentForm />}
					/>
					<Route
						path={APP_ROUTES.LOYAL_CONVEYANCE}
						element={<LoyalConveyanceTable />}
					/>
					<Route
						path={APP_ROUTES.ADD_LOYAL_CONVEYANCE}
						element={<AddLoyalConveyance />}
					/>{' '}
					<Route
						path={APP_ROUTES.TRAVEL_EXPENSES_ARCHIVE}
						element={<TravelExpensesArchiveTable />}
					/>{' '}
					<Route
						path={APP_ROUTES.TRAVEL_EXPENSES_CANCELLATION}
						element={<TravelExpensesCancellationTable />}
					/>{' '}
					<Route
						path={APP_ROUTES.ARCHIVE_REQUEST}
						element={<ArchiveRequestTotalTable />}
					/>
					<Route
						path={APP_ROUTES.TRAVEL_REQUISITION_ARCHIVED_DETAILS}
						element={<TravelRequisitionArchivedDetails />}
					/>
					<Route
						path={APP_ROUTES.TRAVEL_REQUISITION_CANCELLED_DETAILS}
						element={<TravelRequisitionCancellationDetails />}
					/>
					<Route
						path={APP_ROUTES.TRAVEL_REQUISITION_EXPENSES}
						element={<TravelExpensesClaimDetails />}
					/>
					<Route
						path={APP_ROUTES.CHANGE_PASSWORD}
						element={<ChangePassword/>}
					/>
					<Route
						path={APP_ROUTES.MY_PROFILE}
						element={<ProfileScreen/>}
					/>
					<Route path={APP_ROUTES.CLAIM_FORM} element={<ClaimForm />} />{' '}
					{/* <Route path={APP_ROUTES.CLAIM_FORM} element={<ClaimForm />} /> */}
					{!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
						<Route path="*" element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
					) : (
						<></>
					)}
				</Route>
			) : (
				<></>
			)}
		</Routes>
	);
};

export default AppRoutes;
