import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { GridExpandMoreIcon } from '@mui/x-data-grid';
import TableComponent from '../../../common/ui/TableComponent';
type FormData = {
	selectedDate: Date | null;
	// Add other form fields here
};
const ClaimForm = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			country: '',
			travel_from_date: '',
			travel_to_date: '',
			currency_convert: '',
			req_no: '',
		},
	});
	const handleReset = () => {
		setValue('country', '');
		setValue('currency_convert', '');
		setValue('req_no', '');
	};

	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};
	}, []);

	const { teamMember } = useSelector((state: any) => state);

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		navigate(APP_ROUTES.TRAVEL_REQUISITION_EXPENSES);
	};
	const countryList = [
		{ id: 1, name: 'India' },
		{ id: 2, name: 'United States' },
		{ id: 3, name: 'Qatar' },
		{ id: 4, name: 'Japan' },
	];
	const currencyList = [
		{ id: 1, name: 'India' },
		{ id: 2, name: 'United States' },
		{ id: 3, name: 'Qatar' },
		{ id: 4, name: 'Japan' },
	];
	const reqList = [
		{ id: 1, name: 'India' },
		{ id: 2, name: 'United States' },
		{ id: 3, name: 'Qatar' },
		{ id: 4, name: 'Japan' },
	];
	const handleCountry = (value: any) => {
		setValue('country', value, {
			shouldValidate: true,
		});
	};
	const handleCurrency = (value: any) => {
		setValue('currency_convert', value, {
			shouldValidate: true,
		});
	};
	const handleReq = (value: any) => {
		setValue('req_no', value, {
			shouldValidate: true,
		});
	};
	const [expandAll, setExpandAll] = React.useState<string | false>(false);
	const [expandDomestic, setExpandDomestic] = React.useState<string | false>(
		false
	);
	const [expanInternational, setExpandInternational] = React.useState<
		string | false
	>(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};
	const handleExpandAll =
		(panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
			setExpandAll(isExpanded ? 'panel1' : false);
			setExpandDomestic(isExpanded ? 'panel2' : false);
			setExpandInternational(isExpanded ? 'panel3' : false);
		};
	const handleChange =
		(panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
			if (panel === 'panel2') {
				setExpandDomestic(isExpanded ? 'panel2' : false);
			} else if (panel === 'panel3') {
				setExpandInternational(isExpanded ? 'panel3' : false);
			}
		};
	const domesticColumn1: any = [
		{
			headerName: 'Vehicle',
			field: 'vehicle',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Vehicle Elegibility',
			field: 'vehicle_elegibility',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Air',
			field: 'air',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Train',
			field: 'train',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Own Car',
			field: 'own_car',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Own Bike',
			field: 'own_bike',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
	];
	const domesticColumn2: any = [
		{
			headerName: 'Class',
			field: 'class',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Hotel',
			field: 'hotel',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Boarding',
			field: 'boarding',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'HQ-Local Conveyance',
			field: 'hq_local',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Local Conveyance',
			field: 'local_conveyance',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
	];
	const domesticRow1: any = [
		{
			id: 1,
			vehicle: 'NO:1',
			vehicle_elegibility: 'NA',
			air: 'NA',
			train: '34',
			own_car: '34',
			own_bike: '879',
		},
	];
	const domesticRow2: any = [
		{
			id: 1,
			class: 'A',
			hotel: 'hotel 1',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
		{
			id: 2,
			class: 'B',
			hotel: 'hotel 2',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
		{
			id: 3,
			class: 'C',
			hotel: 'hotel 3',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
	];
	const InternationalColumn1: any = [
		{
			headerName: 'Vehicle',
			field: 'vehicle',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Vehicle Elegibility',
			field: 'vehicle_elegibility',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Air',
			field: 'air',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Train',
			field: 'train',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Own Car',
			field: 'own_car',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Own Bike',
			field: 'own_bike',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
	];
	const internationalColumn2: any = [
		{
			headerName: 'Class',
			field: 'class',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Hotel',
			field: 'hotel',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Boarding',
			field: 'boarding',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'HQ-Local Conveyance',
			field: 'hq_local',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Local Conveyance',
			field: 'local_conveyance',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
	];
	const internationalRow1: any = [
		{
			id: 1,
			vehicle: 'NO:1',
			vehicle_elegibility: 'NA',
			air: 'NA',
			train: '34',
			own_car: '34',
			own_bike: '879',
		},
	];
	const internationalRow2: any = [
		{
			id: 1,
			class: 'A',
			hotel: 'hotel 1',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
		{
			id: 2,
			class: 'B',
			hotel: 'hotel 2',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
		{
			id: 3,
			class: 'C',
			hotel: 'hotel 3',
			boarding: 'NA',
			hq_local: 'available',
			local_conveyance: 'available',
		},
	];
	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					// justifyContent="center"
					// className='login-form'
				>
					<Grid item xs={12} p={2} display={'flex'} justifyContent={'flex-end'}>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.TRAVEL_REQUISITION_EXPENSES);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<Accordion
							expanded={expandAll === 'panel1'}
							onChange={handleExpandAll('panel1')}
							style={{ boxShadow: 'none' }}
						>
							<AccordionSummary>
								<Grid
									container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Grid item>
										<Typography
											fontSize={20}
											fontWeight={600}
											color={theme.palette.common.black}
										>
											{t('TRAVEL_EXPENSES_CALIM_FORM')}
										</Typography>
									</Grid>
									<Grid item>
										<CustomButton
											text="View Elegibility"
											className="p-12 secondary-button"
										/>
									</Grid>
								</Grid>
							</AccordionSummary>
						</Accordion>
						<Accordion
							expanded={expandDomestic === 'panel2'}
							onChange={handleChange('panel2')}
							style={{ boxShadow: 'none' }}
						>
							<AccordionSummary
								expandIcon={<GridExpandMoreIcon />}
								aria-controls="panel2bh-content"
								id="panel2bh-header"
							>
								<Typography
									fontSize={20}
									fontWeight={600}
									color={theme.palette.common.black}
								>
									{t('DOMESTIC_ELEGIBILITY')}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TableComponent
									rows={internationalRow1}
									columns={InternationalColumn1}
									totalRecords={internationalRow1.length}
									paginationModel={{ page: currentPage, pageSize: pageSize }}
									handlePagination={handlePaginationChange}
								/>
								<TableComponent
									rows={internationalRow2}
									totalRecords={internationalRow1.length}
									columns={internationalColumn2}
									paginationModel={{ page: currentPage, pageSize: pageSize }}
									handlePagination={handlePaginationChange}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={expanInternational === 'panel3'}
							onChange={handleChange('panel3')}
							style={{ boxShadow: 'none' }}
						>
							<AccordionSummary
								expandIcon={<GridExpandMoreIcon />}
								aria-controls="panel3bh-content"
								id="panel3bh-header"
							>
								<Typography
									fontSize={20}
									fontWeight={600}
									color={theme.palette.common.black}
								>
									{t('INTERNATIONAL_ELEGIBILITY')}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TableComponent
									rows={domesticRow1}
									columns={domesticColumn1}
									totalRecords={domesticRow1.length}
									paginationModel={{ page: currentPage, pageSize: pageSize }}
									handlePagination={handlePaginationChange}
								/>
								<TableComponent
									rows={domesticRow2}
									columns={domesticColumn2}
									totalRecords={domesticRow2.length}
									paginationModel={{ page: currentPage, pageSize: pageSize }}
									handlePagination={handlePaginationChange}
								/>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('COUNTRY')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<Controller
								control={control}
								name={'country'}
								render={({ field }) => (
									<Select
										options={countryList}
										isSearchable
										placeholder={t('SELECT_LEAVE_TYPE')}
										value={
											countryList.find(
												(option) => option.id === parseInt(watch('country'))
											) || null
										}
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => option.id}
										onChange={(selectedOption) => {
											handleCountry(selectedOption?.id || '');
											field.onChange(selectedOption?.id || '');
										}}
										onBlur={() => field.onBlur()}
										isClearable
										defaultValue={location.state?.data?.role_id}
									/>
								)}
								rules={{ required: t('LEAVE_TYPE_IS_REQUIRED') }}
							/>

							{errors?.country?.message ? (
								<Typography color={theme.palette.error.main} fontSize={12}>
									<>{errors?.country?.message}</>
								</Typography>
							) : null}
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('CURRENCY_CONVERT')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<Controller
								control={control}
								name={'currency_convert'}
								render={({ field }) => (
									<Select
										options={currencyList}
										isSearchable
										placeholder={t('SELECT_CURRENCY')}
										value={
											currencyList.find(
												(option) => option.id === parseInt(watch('country'))
											) || null
										}
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => option.id}
										onChange={(selectedOption) => {
											handleCurrency(selectedOption?.id || '');
											field.onChange(selectedOption?.id || '');
										}}
										onBlur={() => field.onBlur()}
										isClearable
										defaultValue={location.state?.data?.role_id}
									/>
								)}
								rules={{ required: t('CURRENCY_IS_REQUIRED') }}
							/>

							{errors?.currency_convert?.message ? (
								<Typography color={theme.palette.error.main} fontSize={12}>
									<>{errors?.currency_convert?.message}</>
								</Typography>
							) : null}
						</Grid>{' '}
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('TRAVEL_REQ_NO')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<Controller
								control={control}
								name={'req_no'}
								render={({ field }) => (
									<Select
										options={reqList}
										isSearchable
										placeholder={t('SELECT_REQ_NO')}
										value={
											reqList.find(
												(option) => option.id === parseInt(watch('req_no'))
											) || null
										}
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => option.id}
										onChange={(selectedOption) => {
											handleReq(selectedOption?.id || '');
											field.onChange(selectedOption?.id || '');
										}}
										onBlur={() => field.onBlur()}
										isClearable
										defaultValue={location.state?.data?.role_id}
									/>
								)}
								rules={{ required: t('REQ_NO_IS_REQUIRED') }}
							/>

							{errors?.req_no?.message ? (
								<Typography color={theme.palette.error.main} fontSize={12}>
									<>{errors?.req_no?.message}</>
								</Typography>
							) : null}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('TRAVEL_TYPE')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								name="travel_type"
								type="text"
								style={{ width: 100 }}
								placeholder={`${t('TRAVEL_TYPE')}...`}
								control={control}
								className="input-from"
								readOnly
								rules={{
									required: {
										value: false,
										message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
									},
								}}
							/>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('TOUR_FROM_DATE')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								name="travel_from_date"
								type="text"
								style={{ width: 100 }}
								placeholder={`${t('TOUR_FROM_DATE')}...`}
								control={control}
								className="input-from"
								readOnly
								rules={{
									required: {
										value: false,
										message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
									},
								}}
							/>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('TOUR_TO_DATE')}
								justifyContent="flex-start"
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								name="travel_to_date"
								type="text"
								style={{ width: 100 }}
								placeholder={`${t('TOUR_FROM_DATE')}...`}
								control={control}
								readOnly
								className="input-from"
								rules={{
									required: {
										value: false,
										message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
									},
								}}
							/>
						</Grid>{' '}
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('SELF_TOTAL')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('COMPANY_TOTAL')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>{' '}
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('GRAND_TOTAL')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('ADVANCE_TOTAL')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>{' '}
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('ADVANCE_RETURN')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} p={2} className="container-list">
							<Grid
								item
								container
								xs={12}
								xl={4}
								lg={6}
								md={4}
								sm={6}
								className="container-item"
							>
								<Grid item xs={10}>
									<LabelWithIcon
										label={t('BALANCE_AMOUNT')}
										labelProps={{ color: 'black', fontSize: 16 }}
										justifyContent="flex-start"
									/>
								</Grid>
								<Grid item>
									<Typography fontSize={20} fontWeight={600}>
										0
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							justifyContent="flex-end"
							display={'flex'}
							my={5}
						>
							<CustomButton
								type="button"
								text={`${t('Reset')}`}
								color={'primary'}
								className="p-12 mx-10 primary-button"
								onClick={handleReset}
							/>
							<CustomButton
								type="submit"
								text="ADD"
								color={'warning'}
								className="p-12 secondary-button"
							/>
						</Grid>{' '}
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default ClaimForm;
