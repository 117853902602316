import { axiosAuthInstance, axiosInstance } from '../../common/axiosInstance';
import ROUTES from '../../common/routes';

const axios = axiosInstance;
const axiosAuth = axiosAuthInstance;

const {
	loginRequest,
    signUp,
	otp,
	otpVerify,
	forgetPassword

	
} = ROUTES;

// TODO: uncomment when working on api


const submitLoginRequestInfo = (data: any) =>
	axios.request({ ...loginRequest, data });
 const submitSignUpRequest = (data: any) => axios.request({ ...signUp, data });
 const submitOtpRequest = (data: any) => axios.request({ ...otp, data });
 const submitOtpVerifyRequest = (data: any) => axios.request({ ...otpVerify, data });
 const submitForgetPasswordRequest = (data: any) => axios.request({ ...forgetPassword, data });





export {
	
	submitLoginRequestInfo,
    submitSignUpRequest,
	submitOtpRequest,
	submitOtpVerifyRequest,
	submitForgetPasswordRequest
	

};
