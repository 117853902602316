import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';
import AttendanceArchivedHeader from './AttendanceArchivedHeader';

function AttendanceReportScreen() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState([]);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePageChange = (page: any) => {};

	const handleDelete = () => {
		setOpen(true);
	};
	const handleDeleteAndSetId = (id: any) => {
		handleDelete();
		setId(id);
	};
	const columns: any = [
		{
			headerName: 'Req.no',
			field: 'reqNo',
			width: 150,
		},
		{
			headerName: 'Emp Code',
			field: 'empCode',
			width: 150,
		},
		{
			headerName: 'Emp Name',
			field: 'empName',
			width: 150,
		},
		{
			headerName: 'Attendance Date',
			field: 'attendanceDate',
			width: 150,
		},
		{
			headerName: 'Shift Name',
			field: 'shiftName',
			width: 150,
		},
		{
			headerName: 'In Time',
			field: 'inTime',
			width: 100,
		},
		{
			headerName: 'Out Time',
			field: 'outTime',
			width: 100,
		},
		{
			headerName: 'Duration',
			field: 'duration',
			width: 100,
		},
		{
			headerName: 'Late Min',
			field: 'lateMin',
			width: 100,
		},
		{
			headerName: 'Early Min',
			field: 'earlyMin',
			width: 100,
		},
		{
			headerName: 'Extra Min',
			field: 'extraMin',
			width: 100,
		},
		{
			headerName: 'Defict Min',
			field: 'defictMin',
			width: 100,
		},
		{
			headerName: 'Status',
			field: 'Status',
			width: 200,
		},
	];
	const rows: any = [
		{
			id: 1,
			reqNo: 'REQ001',
			empCode: '(TM001)',
			empName: 'Arun Kumar(TM001)',
			attendanceDate: '07/05/2024',
			shiftName: 'sales_gs_930',
			inTime: '09:30',
			outTime: '10:30',
			duration: '980',
			lateMin: '0',
			earlyMin: '908',
			regularisationType: 'Type A',
			requestDate: '2023-12-10',
			fromDate: '2023-12-15',
			toDate: '2023-12-20',
			Status: 'Approved',
		},
		{
			id: 2,
			reqNo: 'REQ002',
			empCode: '(TM002)',
			empName: 'Surya(TM002)',
			attendanceDate: '07/05/2024',
			shiftName: 'sales_gs_930',
			inTime: '09:30',
			outTime: '10:30',
			duration: '0',
			lateMin: '560',
			earlyMin: '730',
			regularisationType: 'Type B',
			requestDate: '2023-11-05',
			fromDate: '2023-11-10',
			toDate: '2023-11-15',
			Status: 'Pending',
		},
		{
			id: 3,
			reqNo: 'REQ003',
			empCode: '(TM003)',
			empName: 'Keerthi(TM003)',
			attendanceDate: '07/05/2024',
			shiftName: 'sales_gs_930',
			inTime: '09:30',
			outTime: '10:30',
			duration: '0',
			lateMin: '50',
			earlyMin: '0',
			regularisationType: 'Type C',
			requestDate: '2023-10-20',
			fromDate: '2023-10-25',
			toDate: '2023-11-05',
			Status: 'Rejected',
		},
	];

	// Pass this 'rows' array as a prop to your TableComponent

	return (
		<Grid container>
			<Grid xs={12} mb={5}>
				<Grid
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					className="donation-header"
				>
					<Typography fontSize={20} fontWeight={700}>
						Attendance Report
					</Typography>

					<></>
				</Grid>
			</Grid>
			<Grid
				container
				display="flex"
				mb={2}
				spacing={2}
				// className='login-form'
			>
				<Grid item xs={6} sm={6} md={3} lg={3} xl={2} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid item xs={6} sm={6} md={3} lg={3} xl={2} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>

				<Grid
					xs={12}
					sm={9}
					md={4}
					lg={3}
					xl={2}
					item
					container
					spacing={3}
					className="align-center"
				>
					<Grid item>
						<CustomButton
							text={t('Search')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>
					<Grid item>
						<CustomButton
							text={'Export to excel'}
							className="px-5 py-3 primary-button"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					totalRecords={rows.length}
					handlePagination={handlePaginationChange}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>
		</Grid>
	);
}
export default AttendanceReportScreen;
