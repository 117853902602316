import { Grid, Typography, useTheme } from '@mui/material';
import Select from 'react-select';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import './Login.scss';

import { ReactComponent as icon } from '../../common/icons/Group 141.svg';

import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES, AUTH_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import FormInput from '../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../common/ui/CustomButton';
import LabelWithIcon from '../../common/ui/LabelWithIcon';
import CustomSelectBox from '../../common/ui/Selectbox/CustomSelect';
import { useTranslation } from 'react-i18next';
import {
	fetchLoginRequest,
	signUpRequest,
	fetchOtpRequest,
} from '../../store/auth/_auth.slice';

import { useAppSelector } from '../../store/store';

import { candidateId } from '../../common/utils';
const SignupForm = () => {
	
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [password, setPassword] = useState(true);
	const [model, setModel] = useState(false);



	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
	} = useForm();

	const onSubmit = (data: any) => {
	
	};
	useEffect(() => {
		
	}, []);
	const handleDonatedBy = (value: any) => {
		setValue('candidate_id', value, { shouldValidate: true });
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	useEffect(() => {

	}, []);
	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					flexDirection="row"
					justifyContent="center"
					className="sign-up-form"
					spacing={1}
				>
					<Grid justifyContent="center" display={'flex'} my={1}>
						{/* <SvgConverter
							Icon={icon}
							width={400}
							className="sivabakkiyam-logo"
						/> */}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} my={1} >
						<LabelWithIcon
							label={t('FULL_NAME')}
							// iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
							justifyContent={'flex-start'}
							// className='dob-input'
						/>
						<FormInput
							placeholder={`${t('ENTER_YOUR_FULL_NAME')}...`}
							className="username-input"
							name="name"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_FULL_NAME'),
								},
							}}
							inputProps={{
								className: 'text-center',
								...register('name', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} my={1}>
						<LabelWithIcon
							label={t('EMAIL')}
							// iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
							justifyContent={'flex-start'}
							// className='dob-input'
						/>
						<FormInput
							placeholder={`${t('ENTER_YOUR_EMAIL')}...`}
							className="username-input"
							name="email"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_EMAIL'),
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: `${t('INVALID_EMAIL_ADDRESS')}`,
								},
							}}
							inputProps={{
								className: 'text-center',
								...register('email', {}),
								onChange: handleEmailChange,
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} my={1}>
						<LabelWithIcon
							label={t('PASSWORD')}
							// iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
							justifyContent={'flex-start'}
							// className='dob-input'
						/>
						<FormInput
							placeholder={`${t('ENTER_YOUR_PASSWORD')}...`}
							className="username-input"
							name="password"
							type="password"
							control={control}
							rules={{
								required: {
									value: password,
									message: t('PLEASE_ENTER_YOUR_PASSWORD'),
								},
							}}
							inputProps={{
								className: 'text-center',
								...register('password', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} my={1}>
						<LabelWithIcon
							label={t('MOBILE_NUMBER')}
							// iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
							justifyContent={'flex-start'}
							// className='dob-input'
						/>
						<FormInput
							placeholder={`${t('ENTER_YOUR_MOBILE_NUMBER')}...`}
							className="username-input"
							name="phone"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_MOBILE_NUMBER'),
								},
							}}
							inputProps={{
								className: 'text-center',
								...register('phone', {}),
							}}
							errors={errors}
						/>
					</Grid>
					{/* 
          <Grid item xs={6} my={1}> 
		  <LabelWithIcon
        label={t('CANDIDATE')}
        justifyContent="flex-start"
        labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
    />            
              <Controller
                control={control}
                name='candidate_id'
                render={({ field }) => (
                  <Select
                    options={candidateList}
                    isSearchable
                    placeholder={t('SELECT_CANDIDATE')}
                    value={
                      candidateList.find(
                        (option: any) => option.id === watch('candidate_id')
                      ) || null
                    }
                    getOptionLabel={(option: any) => `${option.full_name}`}
                    getOptionValue={(option: any) => option.id}
                    onChange={(selectedOption: any) => {
                      handleDonatedBy(selectedOption?.id || '');
                      field.onChange(selectedOption?.id || '');
                    }}
                    onBlur={() => field.onBlur()}
                    isClearable
                  
                  />
                  
                )}
                rules={{ required: `${t('CANDIDATE_REQUIRED')} `}}
              />
              {errors?.candidate_id?.message ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                <>{errors?.candidate_id?.message}</> 
                </Typography>
              ) : null}
            </Grid> */}

					<Grid item xs={12} my={1}>
						<LabelWithIcon
							label={'Address'}
							// iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
							justifyContent={'flex-start'}
							// className='dob-input'
						/>
						<FormInput
							placeholder={`${t('ENTER_YOUR_ADDRESS')}...`}
							className="username-input"
							name="address"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_A_ADDRESS'),
								},
							}}
							inputProps={{
								className: 'text-center',
								...register('address', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid justifyContent="flex-end" display={'flex'} my={1}>
						<Link to={AUTH_ROUTES.LOGIN} color={theme.palette.common.white}>
							<Typography color={theme.palette.common.white}>
								{t('ALREADY_HAVE_ACCOUNT_SIGNUP')}
							</Typography>
						</Link>
					</Grid>
					<Grid item xs={12} justifyContent="center" display={'flex'} my={2}>
						<CustomButton
							type="submit"
							text={t('SIGN_UP')}
							className="login-button"
							color={'primary'}
						/>
					</Grid>
				</Grid>
			</form>
			{/* <OtpModel open={model} setOpen={setModel} /> */}
		</Grid>
	);
};

export default SignupForm;
