import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

interface alertInfo {
  title?: string;
  open: boolean;
  type?: 'SUCCESS' | 'FAILED' | 'INFO' | 'PROGRESS';
  description?: string;
  loading?: boolean;
}
interface CommonState {
  alertInfo: alertInfo;
}

const initialState: CommonState = {
  alertInfo: { open: false },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAlertInfo: (state, { payload }: { payload: alertInfo }) => {
      state.alertInfo = { ...state.alertInfo, ...payload };
    },
    dismissAlert: (state) => {
      state.alertInfo = { open: false };
    },
  },
});

export const { setAlertInfo, dismissAlert } = commonSlice.actions;
export default commonSlice;

export const selectAlertInfo = (state: RootState) => state.common.alertInfo;
