import React, { useEffect } from 'react';
import {
	Grid,
	SwipeableDrawer,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Separator from '../../common/ui/Separator';
import { APP_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import MenuIcon from '@mui/icons-material/Menu';
import LogoIcon from '../../assets/images/political_logo.png';
import Person2Icon from '../../common/icons/profile-png-5.png';
import Person5Icon from '../../common/icons/80 (1).png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { candidateId } from '../../common/utils';
import TopMenu from './TopMenu';
import LeftMenuList from './LeftMenuList';

const TopHeader = ({
	setDrawerOpen = () => {},
	setNotificationDrawer = () => {},
	contentHeight = 0,
	drawerOpen,
}: {
	setDrawerOpen?: Function;
	setNotificationDrawer?: Function;
	contentHeight?: number | undefined;
	drawerOpen?: any;
}) => {
	const theme = useTheme();
	const role_id = localStorage.getItem('role_id');
	const name = localStorage.getItem('name');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const logo = useSelector(Logo);
	const handleLogOff = () => {
		localStorage.removeItem('user');
		navigate(APP_ROUTES.DASHBOARD);
		window.location.reload();
	};
	const isMobile = useMediaQuery('(max-width:700px)');
	// useEffect(() => {
	// 	dispatch(candidateDetailsInProgress(candidateId));
	// }, []);
	return (
		<Grid container className="dashBoard-header-menu  py-3">
			<Grid
				item
				className="mr-10 ml-15 menu-bar"
				display={'flex'}
				alignItems={'center'}
			>
				<IconButton
					// color='inherit'
					aria-label="open drawer"
					onClick={() =>
						drawerOpen === true ? setDrawerOpen(false) : setDrawerOpen(true)
					}
					edge="start"
				>
					<MenuIcon />
				</IconButton>
			</Grid>
			<Grid item flex={1} className="mr-20">
				<Grid
					item
					justifyContent={'space-between  '}
					className="header-profile"
					alignItems={'center'}
				>
					<Grid className="logo-icon mt-5 my-4 ">
						<img src={Person5Icon} alt="logo" className="img-logo " />
					</Grid>
					{/* <Grid
						item
						display={'flex'}
						// justifyContent={'center'}
						// alignItems={'center'}
					>
						{' '}
						{!isMobile ? (
							<TopMenu />
						) : (
							<SwipeableDrawer
								className="notification-drawer-container"
								anchor="left"
								open={drawerOpen}
								onClose={() => setDrawerOpen(false)}
								onOpen={() => setDrawerOpen(true)}
							>
								<LeftMenuList setOpen={setDrawerOpen} />
							</SwipeableDrawer>
						)}{' '}
					</Grid> */}
					<Grid
						item
						display={'flex'}
						// justifyContent={'center'}
						// alignItems={'center'}
					>
						<Grid className="profile-img mt-5 mr-20">
							<img src={Person2Icon} alt="logo" className="img-logo" />
						</Grid>

						<Grid my={1}>
							<Typography
								color={theme.palette.common.black}
								display={'flex'}
								fontSize={14}
								whiteSpace={'nowrap'}
							>
								{name}
								<Typography
									color={theme.palette.common.white}
									fontStyle={'italic'}
									fontSize={14}
								>
									(
									{role_id === '2'
										? 'Candidate'
										: role_id === '3'
										? 'people'
										: 'Admin'}
									)
								</Typography>
							</Typography>

							<Link to={'#'} onClick={handleLogOff}>
								<Typography
									display={'flex'}
									alignItems={'center'}
									color={theme.palette.common.white}
									fontSize={14}
								>
									log out &nbsp;{' '}
									<PowerSettingsNewIcon
										className="f-15 mt-2"
										style={{ color: 'white' }}
									/>
								</Typography>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TopHeader;
