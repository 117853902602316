import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
function TravelRequisitionCancelledHeader(type:any) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleNavigate = () => {
		navigate(APP_ROUTES.DASHBOARD);
	};

	return (
		<Grid
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			className="donation-header"
		>
            <Grid>
			<Typography fontSize={20} fontWeight={700}>
				Travel Requisition Cancelled Details
			</Typography>
            </Grid>

            <Grid>
			
			</Grid>
		</Grid>
	);
}

export default TravelRequisitionCancelledHeader;
