import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AUTH_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
import Login from '../features/auth/Login';
import SignUp from '../features/auth/Signup';
import ForgetPasswordScreen from '../features/auth/ForgetPasswordScreen';
import OtpScreen from '../common/ui/OtpScreen';
import AuthLayout from './AuthLayout';

const AuthRoutes = ({ isLogged }: any) => {
	const location = useLocation();
	// const user = localStorage.getItem('user');
	const user =null;
	console.log("isLogged",isLogged)
	return (
		<>
		 <Routes>
		 {isLogged === false ? (
		   <Route path='/' element={<AuthLayout />}>
			 <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
			 <Route path={AUTH_ROUTES.SIGNUP} element={<SignUp />} />
			 <Route
						path={AUTH_ROUTES.FORGET_PASSWORD}
						element={<ForgetPasswordScreen />}

					/>
					<Route
						path={AUTH_ROUTES.OTP_SCREEN}
						element={<OtpScreen/>}
					/>
			 {!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
			   <Route path='*' />
			 ) : (
			   <></>
			 )}
   
			 {/* <Route path={"*"} element={<Navigate to={APP_ROUTES.DASHBOARD} />} /> */}
		   </Route>
		 ) : (
		   <></>
		 )}
	   </Routes>
	   </>
	);
};
export default AuthRoutes;
