import {
	Grid,
	IconButton,
	List,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_ROUTES, menuItems } from '../../common/constants/Routes';
import LanguageSelector from '../../features/auth/LanguageSelector';
import { useDispatch } from 'react-redux';
import { logoutRequest } from '../../store/auth/_auth.slice';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../common/ui/CustomButton';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [showLeaveSubMenu, setShowLeaveSubMenu] = useState(false);
	const [showTravelSubMenu, setShowTravelSubMenu] = useState(false);

	const handleMenuItemClick = () => {
		setOpen(false);
		setShowLeaveSubMenu(false); // Close leave submenu
		setShowTravelSubMenu(false); // Close submenu when a menu item is clicked
	};

	const handleLogOff = () => {
		setOpen(false);
		dispatch(logoutRequest(''));
		navigate(AUTH_ROUTES.LOGIN);
		window.location.reload();
	};

	const handleLeaveClick = () => {
		setShowLeaveSubMenu(!showLeaveSubMenu); // Toggle leave submenu visibility
		setShowTravelSubMenu(false); // Close travel submenu when opening leave submenu
	};

	const handleTravelClick = () => {
		setShowTravelSubMenu(!showTravelSubMenu); // Toggle travel submenu visibility
		setShowLeaveSubMenu(false); // Close leave submenu when opening travel submenu
	};

	return (
		<div className="notification-container">
			<Grid
				container
				justifyContent="space-between"
				className="notification-drawer-header"
				alignItems="center"

			>
	
				<Grid item mt={5}>
					<IconButton onClick={() => setOpen(false)}>
						<CloseIcon />
					</IconButton>
					<List className="left-menu-container">
						{menuItems.map((item: any, index: any) => (
							<React.Fragment key={index}>
								{item.children && item.module === 'leave' && (
									<>
										<ListItemText
											onClick={handleLeaveClick} // Toggle submenu visibility on Leave item click
											primary={
												<Typography
													color={theme.palette.secondary.main}
													fontWeight={600}
													fontSize={18}
													className="menu-list"
												>
													{t(item.text)}
												</Typography>
											}
										/>
										{showLeaveSubMenu && (
											<List className="submenu-container">
												{item.children.map((child: any, idx: any) => (
													<Link
														to={child.route}
														key={idx}
														className="submenu-item"
														onClick={handleMenuItemClick}
													>
														<ListItemText
															primary={
																<Typography
																	color={theme.palette.secondary.main}
																	fontWeight={600}
																	fontSize={18}
																	className="menu-list"
																>
																	{t(child.text)}
																</Typography>
															}
														/>
													</Link>
												))}
											</List>
										)}
									</>
								)}
								{item.children && item.module === 'travel' && (
									<>
										<ListItemText
											onClick={handleTravelClick} // Toggle submenu visibility on Leave item click
											primary={
												<Typography
													color={theme.palette.secondary.main}
													fontWeight={600}
													fontSize={18}
													className="menu-list"
												>
													{t(item.text)}
												</Typography>
											}
										/>
										{showTravelSubMenu && (
											<List className="submenu-container">
												{item.children.map((child: any, idx: any) => (
													<Link
														to={child.route}
														key={idx}
														className="submenu-item"
														onClick={handleMenuItemClick}
													>
														<ListItemText
															primary={
																<Typography
																	color={theme.palette.secondary.main}
																	fontWeight={600}
																	fontSize={18}
																	className="menu-list"
																>
																	{t(child.text)}
																</Typography>
															}
														/>
													</Link>
												))}
											</List>
										)}
									</>
								)}
								{!item.children && (
									<Link
										to={item.route}
										onClick={handleMenuItemClick}
										className={`menu-item-container mt-30${
											index > 0 ? ' mt-10' : ''
										}`}
									>
										<ListItemText
											primary={
												<Typography
													color={theme.palette.secondary.main}
													fontWeight={600}
													fontSize={18}
													className="menu-list"
												>
													{t(item.text)}
												</Typography>
											}
										/>
									</Link>
								)}
							</React.Fragment>
						))}
					</List>
					<Link to={'#'} onClick={handleLogOff}>
                  <ListItemText
                    className='menu-item-container mx-45'
                    primary={
                      <Typography
                        color={theme.palette.secondary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        Log Out
             
                      </Typography>
                    }
                  />
                </Link>
				</Grid>
				<Grid>
				
				</Grid>
			</Grid>
			<LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				contentType="DASHBOARD"
			/>
		</div>
	);
};

export default LeftMenuList;
