import {
	FormControl,
	Grid,
	InputLabel,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../dashboard.scss';

import MisselaneousForm from './MisselaneousForm';

type FormData = {
	expenses: {
		name?: any;
		country?: any;
		city?: any;
		currency?: any;
		cost_center?: any;
		date?: any;
		expenses_type?: any;
		mode?: any;
		paid_by?: any;
		elegibility?: any;
		kilo_meter?: any;
		amount?: any;
		total_amount?: any;
		upload_document?: any;
	}[];
};
const AddLoyalConveyance = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedDate, setSelectedDate] = useState('');
	const [error, setError] = useState(false);
	const [selfTotal, setSelfTotal] = useState(0);
	const [companyTotal, setCompanyTotal] = useState(0);
	const [misselaneousTotal, setMisselaneousTotal] = useState(0);
	const [grandTotal, setGrandTotal] = useState(0);

	const {
		control,
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			expenses: [
				{
					name: '',
					country: '',
					city: '',
					currency: '',
					cost_center: '',
					date: null,
					expenses_type: '',
					mode: '',
					paid_by: '',
					elegibility: '',
					kilo_meter: '',
					amount: '',
					total_amount: '',
					upload_document: '',
				},
			],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'expenses',
	});

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		navigate('/loyal-conveyance');
		// navigate(APP_ROUTES.TEAM_MEMBER);
	};
	const handleAddExpenses = () => {
		append({
			country: '',
			city: '',
			currency: '',
			cost_center: '',
			date: '',
			expenses_type: '',
			mode: '',
			paid_by: '',
			elegibility: '',
			kilo_meter: '',
			amount: '',
			total_amount: '',
			upload_document: '',
		});
	};
	const handleCountry = (index: number, value: any) => {
		setValue(`expenses.${index}.country`, value, {
			shouldValidate: true,
		});
	};
	const handleCity = (index: number, value: any) => {
		setValue(`expenses.${index}.city`, value, {
			shouldValidate: true,
		});
	};
	const handleCurrency = (index: number, value: any) => {
		setValue(`expenses.${index}.currency`, value, {
			shouldValidate: true,
		});
	};
	const handleCostCenter = (index: number, value: any) => {
		setValue(`expenses.${index}.cost_center`, value, {
			shouldValidate: true,
		});
	};
	const handleExpenses = (index: number, value: any) => {
		setValue(`expenses.${index}.expenses_type`, value, {
			shouldValidate: true,
		});
	};
	const handleMode = (index: number, value: any) => {
		setValue(`expenses.${index}.mode`, value, {
			shouldValidate: true,
		});
	};
	const handlePaid = (index: number, value: any) => {
		setValue(`expenses.${index}.paid_by`, value, {
			shouldValidate: true,
		});
	};

	const countryList = [
		{ id: 1, name: 'India' },
		{ id: 2, name: 'United States' },
		{ id: 3, name: 'Qatar' },
		{ id: 4, name: 'Japan' },
	];
	const cityList = [
		{ id: 1, name: 'Mumbai' },
		{ id: 2, name: 'Pune' },
		{ id: 3, name: 'Ahmedabad' },
		{ id: 4, name: 'Chennai' },
	];
	const currencyList = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];
	const costCenterList = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];
	const expensesList = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];
	const modeList = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];
	const paidList = [
		{ id: 1, name: 'Company' },
		{ id: 2, name: 'Self' },
	];

	useEffect(() => {
		const total =
			Number(selfTotal) + Number(companyTotal) + Number(misselaneousTotal);
		setGrandTotal(Number(total));
	}, [selfTotal, companyTotal, misselaneousTotal]);
	const handleTotalAmount = (index: number, event: any, field: any) => {
		let amount = 0;
		let kilometer = 0;
		let selfValue = 0;
		let companyValue = 0;
		const expenses = getValues('expenses');
		if (field === 'amount') {
			amount = event.target.value;
			kilometer = getValues(`expenses.${index}.kilo_meter`);
		} else {
			kilometer = event.target.value;
			amount = getValues(`expenses.${index}.amount`);
		}

		const totalAmount = kilometer * amount;

		setValue(`expenses.${index}.total_amount`, totalAmount);
		expenses.forEach((expense: any, index: number) => {
			if (parseInt(expense.paid_by) === 2) {
				selfValue += expense.total_amount;
				console.log('selfValue', selfValue);
				setSelfTotal(selfValue);
			} else {
				companyValue += expense.total_amount;
				setCompanyTotal(companyValue);
			}
		});

		// setSelfTotal(selfValue);
		// setCompanyTotal(companyTotal);
	};
	useEffect(() => {
		let selfValue = 0;
		let companyValue = 0;
		const expenses = getValues('expenses');
		expenses.forEach((expense: any, index: number) => {
			if (parseInt(expense.paid_by) === 2) {
				selfValue += expense.total_amount;
				console.log('selfValue', selfValue);
				setSelfTotal(selfValue);
			} else {
				companyValue += expense.total_amount;
				setSelfTotal(selfValue);
				setCompanyTotal(Number(companyValue));
			}
		});
	}, [[watch('expenses')]]);
	const [readOnly, setReadOnly] = useState(false);
	const [readOnlyFields, setReadOnlyFields] = useState<number[]>([]);

	const handleFreeze = (index: any) => {
		setReadOnly(true);
		setReadOnlyFields([...readOnlyFields, index]);
	};

	const handleResetRow = (index: any) => {
		const updatedReadOnlyFields = readOnlyFields.filter(
			(item) => item !== index
		);
		setReadOnlyFields(updatedReadOnlyFields);
		setValue(`expenses.${index}.country`, null);
		setValue(`expenses.${index}.city`, null);
		setValue(`expenses.${index}.currency`, null);
		setValue(`expenses.${index}.cost_center`, null);
	};

	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					// justifyContent="center"
					// className='login-form'
				>
					<Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.common.black}
						>
							{t('LOYAL_CONVEYANCE_FORM')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.LOYAL_CONVEYANCE);
							}}
						/>
					</Grid>
					<Grid container spacing={2}>
						{fields.map((field, index) => (
							<React.Fragment key={field.id}>
								<Grid item xs={6} sm={6} md={3} lg={2} xl={3}>
									<LabelWithIcon
										label={t('COUNTRY')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.country`}
										render={({ field }) => (
											<Select
												options={countryList}
												isSearchable
												isDisabled={readOnlyFields.includes(index)}
												placeholder={t('SELECT_COUNTRY')}
												value={
													countryList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.country`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleCountry(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('COUNTRY_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.country?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.country?.message}</>
										</Typography>
									) : null}
								</Grid>
								<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
									<LabelWithIcon
										label={t('CITY')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.city`}
										render={({ field }) => (
											<Select
												options={cityList}
												isSearchable
												isDisabled={readOnlyFields.includes(index)}
												placeholder={t('SELECT_CITY')}
												value={
													cityList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.city`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleCity(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('CITY_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.city?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.city?.message}</>
										</Typography>
									) : null}
								</Grid>
								<Grid item xs={6} sm={6} md={2} lg={2} xl={3}>
									<LabelWithIcon
										label={t('CURRENCY')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.currency`}
										render={({ field }) => (
											<Select
												options={currencyList}
												isSearchable
												isDisabled={readOnlyFields.includes(index)}
												placeholder={t('SELECT_CURRENCY')}
												value={
													currencyList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.currency`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleCurrency(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('CURRENCY_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.currency?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.currency?.message}</>
										</Typography>
									) : null}
								</Grid>
								<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
									<LabelWithIcon
										label={t('COST_CENTER')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.cost_center`}
										render={({ field }) => (
											<Select
												options={costCenterList}
												isSearchable
												isDisabled={readOnlyFields.includes(index)}
												placeholder={t('SELECT_COST_CENTER')}
												value={
													costCenterList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.cost_center`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleCostCenter(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('COST_CENTER_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.cost_center?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.cost_center?.message}</>
										</Typography>
									) : null}
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xl={2}
									xs={12}
									sm={12}
									display="flex"
									className="my-20"
								>
									<CustomButton
										type="button"
										text={`${t('FREEZE')}`}
										className="p-12 mx-10 primary-button"
										onClick={() => handleFreeze(index)}
									/>
									<CustomButton
										text="RESET"
										className="p-12 secondary-button"
										onClick={() => handleResetRow(index)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<InputLabel>From Date:</InputLabel>
									<FormControl fullWidth required>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												value={getValues(`expenses.${index}.date`)}
												onChange={(date: any) =>
													setValue(`expenses.${index}.date`, date)
												}
												slotProps={{
													textField: {
														size: 'small',
														...register(`expenses.${index}.date`, {
															required: 'Date is required',
														}),
													},
												}}
											/>
										</LocalizationProvider>

										{errors?.expenses?.[index]?.date && (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{
													(errors &&
														errors?.expenses?.[index]?.date
															?.message) as React.ReactNode
												}
											</Typography>
										)}
									</FormControl>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('EXPENSES_TYPE')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.expenses_type`}
										render={({ field }) => (
											<Select
												options={expensesList}
												isSearchable
												placeholder={t('SELECT_EXPENSES_TYPE')}
												value={
													expensesList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.expenses_type`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleExpenses(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('EXPENSES_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.expenses_type?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.expenses_type?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('MODE')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.mode`}
										render={({ field }) => (
											<Select
												options={modeList}
												isSearchable
												placeholder={t('SELECT_EXPENSES_TYPE')}
												value={
													modeList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.mode`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handleMode(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('MODE_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.mode?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.mode?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('PAID_BY')}
										justifyContent="flex-start"
										labelProps={{ color: 'black' }}
									/>
									<Controller
										control={control}
										name={`expenses.${index}.paid_by`}
										render={({ field }) => (
											<Select
												options={paidList}
												isSearchable
												placeholder={t('SELECT_PAID_BY')}
												value={
													paidList.find(
														(option) =>
															option.id ===
															parseInt(watch(`expenses.${index}.paid_by`))
													) || null
												}
												getOptionLabel={(option) => `${option.name}`}
												getOptionValue={(option) => option.id}
												onChange={(selectedOption) => {
													handlePaid(index, selectedOption?.id || '');
													field.onChange(selectedOption?.id || '');
												}}
												onBlur={() => field.onBlur()}
												isClearable
												defaultValue={location.state?.data?.role_id}
											/>
										)}
										rules={{ required: t('PAID_BY_IS_REQUIRED') }}
									/>

									{errors?.expenses?.[index]?.paid_by?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.paid_by?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('ELEGIBILITY')}
										labelProps={{ color: 'black' }}
										justifyContent="flex-start"
									/>
									<FormInput
										name={`expenses.${index}.elegibility`}
										type="text"
										placeholder={`${t('ELEGIBILITY')}...`}
										control={control}
										// className="input-from-text"
										inputProps={{
											...register(`expenses.${index}.elegibility`, {}),
										}}
										errors={errors}
										rules={{
											required: {
												value: true,
												message: t('ELEGIBILITY_IS_REQUIRED'),
											},
										}}
									/>{' '}
									{errors?.expenses?.[index]?.elegibility?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.elegibility?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('KILO_METER')}
										labelProps={{ color: 'black' }}
										justifyContent="flex-start"
									/>
									<FormInput
										name={`expenses.${index}.kilo_meter`}
										type="text"
										placeholder={`${t('KILO_METER')}...`}
										control={control}
										// className="input-from-text"
										inputProps={{
											...register(`expenses.${index}.kilo_meter`, {}),
											onChange: (event: any) =>
												handleTotalAmount(index, event, 'kilometer'),
										}}
										errors={errors}
										rules={{
											required: {
												value: true,
												message: t('KILOMETER_IS_REQUIRED'),
											},
										}}
									/>
									{errors?.expenses?.[index]?.kilo_meter?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.kilo_meter?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('AMOUNT')}
										labelProps={{ color: 'black' }}
										justifyContent="flex-start"
									/>
									<FormInput
										name={`expenses.${index}.amount`}
										type="text"
										placeholder={`${t('AMOUNT')}...`}
										control={control}
										// className="input-from-text"
										inputProps={{
											...register(`expenses.${index}.amount`),
											onChange: (event: any) =>
												handleTotalAmount(index, event, 'amount'),
										}}
										errors={errors}
										rules={{
											required: {
												value: true,
												message: `${t('AMOUNT_IS_REQUIRED')}`,
											},
										}}
									/>{' '}
									{errors?.expenses?.[index]?.amount?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.amount?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('TOTAL_AMOUNT')}
										labelProps={{ color: 'black' }}
										justifyContent="flex-start"
									/>
									<FormInput
										name={`expenses.${index}.total_amount`}
										type="text"
										placeholder={`${t('TOTAL_AMOUNT')}...`}
										control={control}
										// className="input-from-text"
										inputProps={{
											...register(`expenses.${index}.total_amount`, {}),
										}}
										errors={errors}
										rules={{
											required: {
												value: true,
												message: `${t('TOTAL_AMOUNT_IS_REQUIRED')}`,
											},
										}}
									/>{' '}
									{errors?.expenses?.[index]?.total_amount?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.total_amount?.message}</>
										</Typography>
									) : null}
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<LabelWithIcon
										label={t('UPLOAD_DOCUMEMT')}
										labelProps={{ color: 'black' }}
										justifyContent="flex-start"
									/>
									<FormInput
										name={`expenses.${index}.upload_document`}
										type="file"
										placeholder={`${t('UPLOAD_DOCUMEMT')}...`}
										control={control}
										// className="input-from-text"
										inputProps={{
											...register(`expenses.${index}.upload_document`, {}),
										}}
										errors={errors}
										rules={{
											required: {
												value: true,
												message: `${t('UPLOAD_DOCUMENT_IS_REQUIRED')}`,
											},
										}}
									/>{' '}
									{errors?.expenses?.[index]?.upload_document?.message ? (
										<Typography color={theme.palette.error.main} fontSize={12}>
											<>{errors?.expenses?.[index]?.upload_document?.message}</>
										</Typography>
									) : null}
								</Grid>
								<Grid item xs={12}>
									{index !== 0 && (
										<Grid item xs={12}>
											<CustomButton
												type="button"
												text={t('REMOVE')}
												className=" px-10 py-5 my-5 secondary-button"
												onClick={() => remove(index)}
											/>
										</Grid>
									)}
								</Grid>
							</React.Fragment>
						))}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<CustomButton
							type="button"
							text={t('ADD')}
							className=" px-10 py-5 my-5 primary-button"
							fontSize={14}
							onClick={() => handleAddExpenses()}
						/>
					</Grid>
					<Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
						<MisselaneousForm
							setMisselaneousTotal={setMisselaneousTotal}
							misselaneousTotal={misselaneousTotal}
							errors={errors}
							register={register}
							control={control}
						/>
					</Grid>
					<Grid item container xs={12} className="container-list">
						<Grid
							item
							container
							xs={12}
							xl={4}
							lg={6}
							md={4}
							sm={6}
							className="container-item"
						>
							<Grid item xs={10}>
								<LabelWithIcon
									label={t('SELF_TOTAL')}
									labelProps={{ color: 'black', fontSize: 16 }}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item>
								<Typography fontSize={20} fontWeight={600}>
									{selfTotal}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} className="container-list">
						<Grid
							item
							container
							xs={12}
							xl={4}
							lg={6}
							md={4}
							sm={6}
							className="container-item"
						>
							<Grid item xs={10}>
								<LabelWithIcon
									label={t('COMPANY_TOTAL')}
									labelProps={{ color: 'black', fontSize: 16 }}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item>
								{' '}
								<Typography fontSize={20} fontWeight={600}>
									{' '}
									{companyTotal}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} className="container-list">
						<Grid
							item
							container
							xs={12}
							xl={4}
							lg={6}
							md={4}
							sm={6}
							className="container-item"
						>
							<Grid item xs={10}>
								<LabelWithIcon
									label={t('MISSELANEOUS_TOTAL')}
									labelProps={{ color: 'black', fontSize: 16 }}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item>
								{' '}
								<Typography fontSize={20} fontWeight={600}>
									{misselaneousTotal}
								</Typography>
							</Grid>
						</Grid>
					</Grid>{' '}
					<Grid item container xs={12} className="container-list">
						<Grid
							item
							container
							xs={12}
							xl={4}
							lg={6}
							md={4}
							sm={6}
							className="container-item"
						>
							<Grid item xs={10}>
								<LabelWithIcon
									label={t('GRAND_TOTAL')}
									labelProps={{ color: 'black', fontSize: 16 }}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item>
								<Typography fontSize={20} fontWeight={600}>
									{grandTotal}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="submit"
							text="Submit"
							className="p-12 secondary-button"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AddLoyalConveyance;
