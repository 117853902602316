import { all } from 'redux-saga/effects';

import authSaga from './auth/_auth.saga';


export default function* rootSaga() {
	yield all([
	
		authSaga(),
		
	]);
	// code after all-effect
}
