import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';
import LeaveHeader from './LeaveHeader';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';

function LeaveCancellation() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [tableRow, setTableRow] = useState([]);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePageChange = (page: any) => {};

	const handleDelete = () => {
		setOpen(true);
	};
	const handleDeleteAndSetId = (id: any) => {
		handleDelete();
		setId(id);
	};
	const columns: any = [
		{
			headerName: 's.no',
			field: 'id',
			width: 100,
		},
		{
			headerName: 'Request Number',
			field: 'request_number',
			width: 200,
		},
		{
			headerName: 'Request Date',
			field: 'request_date',
			width: 200,
		},
		{
			headerName: 'Leave Type',
			field: 'leave_type',
			width: 200,
		},
		{
			headerName: 'From Date',
			field: 'from_date',
			width: 200,
		},
		{
			headerName: 'To Date',
			field: 'to_date',
			width: 200,
		},
		{
			headerName: 'No.of days',
			field: 'days',
			width: 200,
		},
		{
			headerName: 'Cancellation Remarks',
			field: 'cancellation_remarks',
			width: 200,
		},
		{
			headerName: t('ACTION'),
			field: 'Action',
			width: 150,

			renderCell: ({ row }: any) => {
				const handleDeleteClick = () => {
					setDeleteId(row?.id);
					setOpenDeleteModal(true);
				};

				return (
					<>
						<CustomButton
							text={'Delete'}
							className="mx-3 primary-button"
							onClick={handleDeleteClick}
						/>
					</>
				);
			},
		},
	];
	const rows: any = [
		{
			id: 1,
			request_number: 'REQ009',
			request_date: '3-5-2024',
			leave_type: 'vacation',
			from_date: '8-5-2024',
			to_date: '10-5-2024',
			days: '3',
			cancellation_remarks: 'none',
		},
		{
			id: 2,
			request_number: 'REQ009',
			request_date: '3-5-2024',
			leave_type: 'vacation',
			from_date: '8-5-2024',
			to_date: '10-5-2024',
			days: '3',
			cancellation_remarks: 'none',
		},
	];

	return (
		<Grid container>
			<Grid xs={12} mb={5}>
				<Grid
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					className="donation-header"
				>
					<Typography fontSize={20} fontWeight={700}>
						Leave Cancellation
					</Typography>

					<>
						{/* <CustomButton
							type="button"
							text={`Add  Leave`}
							startIcon={AddIcon}
							className=" px-10 py-5 my-5"
							fontSize={14}
							onClick={() => {
								navigate(APP_ROUTES.ADD_LEAVE);
							}}
							color={'primary'}
						/> */}
					</>
				</Grid>
			</Grid>
			{/* <Grid
				container
				display="flex"
				alignItems={'center'}
				mb={2}
				justifyContent={'space-between'}
				// className='login-form'
			>
				<Grid xs={6} sm={4} md={3} lg={1.5} xl={2} mb={3}>
					<Typography className="from-date-text">Search:</Typography>
					<TextField
						label={'Search'}
						size="small"
						variant="outlined"
						//   value={searchQuery}
						//   onChange={handleSearchChange}
					/>
				</Grid>
				<Grid xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Applied Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>

				<Grid xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid
					xs={12}
					sm={7}
					md={3}
					lg={3}
					xl={2}
					display={'flex'}
					justifyContent={'space-between'}
				>
					<CustomButton
						type="button"
						text={t('SEARCH')}
						className="px-5 py-3 primary-button"
					/>
					<CustomButton
						type="button"
						text={t('EXPORT_TO_EXCEL')}
						className="px-5 py-3 primary-button"
					/>
				</Grid>
			</Grid> */}
			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>{' '}
			<DeleteModel
				setOpen={setOpenDeleteModal}
				open={openDeleteModal}
				handleDelete={handleDelete}
				// setDeleteData={setDeleteData}
			/>
		</Grid>
	);
}
export default LeaveCancellation;
