export const AUTH_ROUTES = {
	LOGIN: '/',
	SIGNUP: '/signup',
	FORGET_PASSWORD: '/forget-password',
	OTP_SCREEN: '/otp-screen',
};

// export const APP_ROUTES = {
// 	DASHBOARD: '/',
// 	CANDIDATE: '/candidate',
// 	ADMINCANDIDATE: '/candidate',
// 	PUBLIC_LIST: '/public-list',
// 	ADD_PUBLIC_LIST: '/public/add',
// 	EDIT_PUBLIC_LIST: '/public/edit',
// 	PETITION: '/petition',
// 	ADD_PETITION: '/petition/add',
// 	EDIT_PETITION: '/petition/edit',
// 	PDF_PETITION: '/petition/pdf',
// 	VIEW_PETITION: '/petition/view',
// 	CANDIDATE_PROFILE: '/profile',
// 	CHANGE_PASSWORD: '/change-password',
// };
export const APP_ROUTES = {
	DASHBOARD: '/',
	CANDIDATE: '/candidate',
	ADMINCANDIDATE: '/candidate',
	PUBLIC_LIST: '/public-list',
	ADD_PUBLIC_LIST: '/public/add',
	EDIT_PUBLIC_LIST: '/public/edit',
	ATTENDANCE: '/attendance',
	ATTENDANCE_FORM: '/attendance/add',
	ADD_PETITION: '/petition/add',
	EDIT_PETITION: '/petition/edit',
	PDF_PETITION: '/petition/pdf',
	VIEW_PETITION: '/petition/view',
	CANDIDATE_PROFILE: '/profile',
	CHANGE_PASSWORD: '/change-password',
	ATTENDANCE_REGULARISATION: '/attendance-management/attendance-regularisation',
	ATTENDANCE_REPORT: '/attendance-management/attendance-report',
	ATTENDANCE_ARCHIVED_REPORT:
		'/attendance-management/attendance-archived-report',
	SHORT_LEAVE: '/short-leave',
	ADD_SHORT_LEAVE: '/short-leave/add',
	COMPENSATORY_LEAVE: '/compensatory-leave',
	ADD_COMPENSATORY_LEAVE: '/compensatory-leave/add',
	LEAVE: '/leave',
	ADD_LEAVE: '/leave/add',
	LEAVE_HISTORY: '/leave-history',
	LEAVE_CANCELLATION: '/leave-cancellation',
	LEAVE_ARCHIVED: '/leave-approval-archived-details',
	LEAVE_ENCASHMENT: '/leave-encashment',
	LEAVE_ENCASHMENT_FORM: '/leave-encashment-form',
	MANAGE_TRAVEL: '/manage-travel',
	TRAVEL_REQUISITION: '/travel-requisition',
	ADD_TRAVEL_REQUISITION: '/travel-requisition/add',
	TRAVEL_REQUISITION_FORM: '/travel-requisition-form',
	TRAVEL_REQUISITION_ARCHIVED_DETAILS: '/travel-requisition-archived-details',
	TRAVEL_REQUISITION_CANCELLED_DETAILS: '/travel-requisition-cancelled-details',
	TRAVEL_REQUISITION_EXPENSES: '/travel-requisition-expenses',
	TRAVEL__EXPENSES_CLAIM_FORM: '/travel-claim-form',
	LOYAL_CONVEYANCE: '/loyal-conveyance',
	ADD_LOYAL_CONVEYANCE: '/loyal-conveyance/add',
	TRAVEL_EXPENSES_ARCHIVE: '/travel-expenses-archive',
	TRAVEL_EXPENSES_CANCELLATION: '/travel-expenses-cancellation',
	ARCHIVE_REQUEST: '/archive-request',
	CLAIM_FORM: '/travel-claim-form',
	MY_PROFILE:'my-profile'
	
};
export const menuItems = [
	{
		text: 'Attendance Report',
		route: APP_ROUTES.ATTENDANCE_REPORT,
		module: 'attendance',
	},
	{
		text: 'Attendance Regularisation Details',
		route: APP_ROUTES.ATTENDANCE_REGULARISATION,
		module: 'attendance',
	},
	{
		text: 'Attendance Regularisation Archived Details',
		route: APP_ROUTES.ATTENDANCE_ARCHIVED_REPORT,
		module: 'attendance',
	},

	{
		text: 'Leave',
		module: 'leave',
		children: [
			{
				text: 'Leave',
				route: APP_ROUTES.LEAVE,
			},
			{
				text: 'Short Leave',
				route: APP_ROUTES.SHORT_LEAVE,
			},
			{
				text: 'Compensatory Leave',
				route: APP_ROUTES.COMPENSATORY_LEAVE,
			},
			{
				text: 'Leave Encashment',
				route: APP_ROUTES.LEAVE_ENCASHMENT,
			},
			{
				text: 'Leave History',
				route: APP_ROUTES.LEAVE_HISTORY,
			},
			{
				text: 'Leave Approval Archived Details',
				route: APP_ROUTES.LEAVE_ARCHIVED,
			},
			{
				text: 'Leave Cancellation',
				route: APP_ROUTES.LEAVE_CANCELLATION,
			},
		],
	},
	{
		text: 'Travel',
		module: 'travel',
		children: [
			{
				text: 'Manage Travel',
				route: APP_ROUTES.MANAGE_TRAVEL,
			},
			{
				text: 'Travel Requisition',
				route: APP_ROUTES.TRAVEL_REQUISITION,
			},
			{
				text: 'Travel Requisition Archived Details',
				route: APP_ROUTES.TRAVEL_REQUISITION_ARCHIVED_DETAILS,
			},
			{
				text: 'Travel Requisition Cancelled Details',
				route: APP_ROUTES.TRAVEL_REQUISITION_CANCELLED_DETAILS,
			},
			{
				text: 'Travel Expenses Claims',
				route: APP_ROUTES.TRAVEL_REQUISITION_EXPENSES,
			},
			{
				text: 'Loyal Conveyance',
				route: APP_ROUTES.LOYAL_CONVEYANCE,
			},
			{
				text: 'Travel Expenses Archive',
				route: APP_ROUTES.TRAVEL_EXPENSES_ARCHIVE,
			},
			{
				text: 'Archive Request',
				route: APP_ROUTES.ARCHIVE_REQUEST,
			},
			{
				text: 'Travel Expenses  Cancellation',
				route: APP_ROUTES.TRAVEL_EXPENSES_CANCELLATION,
			},
		],
	},
	{
		text: 'Change Password',
		route: APP_ROUTES.CHANGE_PASSWORD,
		module: 'changePassword',
	},
	{
		text: 'My Profile',
		route: APP_ROUTES.MY_PROFILE,
		module: 'myProfile',
	},

];

export const attendanceItems = [];
export const leaveItems = [];
export const travelItems = [];

export const PUBLIC_ROUTES = {};
