import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
function CompOffHeader() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleNavigate = () => {
		navigate(APP_ROUTES.DASHBOARD);
	};

	return (
		<Grid
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			className="donation-header"
		>
			<Typography fontSize={20} fontWeight={700}>
				Compensatory Off
			</Typography>

			<>
				<CustomButton
					type="button"
					text={`Add Compensatory Leave`}
					startIcon={AddIcon}
					className=" px-10 py-5 my-5 secondary-button"
					fontSize={14}
					onClick={() => {
						navigate(APP_ROUTES.ADD_COMPENSATORY_LEAVE);
					}}
				/>
			</>
		</Grid>
	);
}

export default CompOffHeader;
