import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
	Grid,
	Typography,
	useTheme,
	InputLabel,
	FormControl,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TravelRequsitionFormComponent from './TravelRequsitionFormComponent';

const TravelRequisitionForm = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			fromDate: null,
			toDate: null,
			fromTime: null,
			toTime: null,
			departureDate: null,
			arrivalDate: null,
			departureTime: null,
			arrivalTime: null,
			destinationCity: '',
			purposeOfTravel: '',
			class: '',
			mode: '',
			phone: '',
			regularizationType: '',
			place: '',
			reason: '',
			country: '',
			costCentre: '',
			ticketBookedBy: '',
			remarks: '',
			name: '',
			departureCity: '',
			travellerType: '',
			tourType: '',
			subForm: [],
			accommodationRequired: '', // Default value for checkboxes
			travelAdvanceRequested: '', // Default value for checkboxes
		},
	});
	const [tourType, setTourType] = useState('');

	const handleTourTypeChange = (event: any) => {
		setTourType(event.target.value);
		setValue('tourType', event.target.value); // Update form value
	};
	const handleReset = () => {
		setValue('phone', '');
		setValue('regularizationType', '');
		setValue('place', '');
		setValue('reason', '');
	};

	const handleCategory = (value: any) => {
		setValue('regularizationType', value, {
			shouldValidate: true,
		});
	};

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		console.log('data', data);

		// navigate(APP_ROUTES.TEAM_MEMBER);
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	const regularizationTypes = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
		// Add more types as needed
	];
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'subForm',
	} as any);
	const handleAddFamily = () => {
		const familyMemberDefault = {
			fromDate: null,
			toDate: null,
			fromTime: null,
			toTime: null,
			departureDate: null,
			arrivalDate: null,
			departureTime: null,
			arrivalTime: null,
			destinationCity: '',
			purposeOfTravel: '',
			class: '',
			mode: '',
			phone: '',
			regularizationType: '',
			place: '',
			reason: '',
			country: '',
			costCentre: '',
			ticketBookedBy: '',
			remarks: '',
			name: '',
			departureCity: '',
		};
		append(familyMemberDefault);
	};
	const accommodationRequired = watch('accommodationRequired') === 'true';
	const travelAdvanceRequested = watch('travelAdvanceRequested') === 'true';

	const handleTravelAdvanceChange = (event: any) => {
		setValue('travelAdvanceRequested', event.target.checked.toString());
	};

	const handleAccommodationChange = (event: any) => {
		setValue('accommodationRequired', event.target.checked.toString());
	};

	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.common.black}
						>
							{location?.state?.type === 'edit'
								? `${t('Edit_Team_Member')}`
								: `Add Requisition	`}
						</Typography>
						<CustomButton
							type="button"
							text={`Go Back`}
							startIcon={SubdirectoryArrowRightIcon}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.ATTENDANCE);
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>From Date:</InputLabel>
						<FormControl fullWidth required>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('fromDate')}
									onChange={(date: any) => setValue('fromDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('fromDate', {
												required: 'From Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>

							{errors?.fromDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.fromDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>To Date:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('toDate')}
									onChange={(date: any) => setValue('toDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('toDate', {
												required: 'To Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.toDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.toDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Name:</InputLabel>
						<FormInput
							name="name"
							type="text"
							style={{ width: 100 }}
							placeholder={`Name...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: `Please Enter the name `,
								},
							}}
							inputProps={{
								...register('name', {}),
							}}
							errors={errors}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Remarks:</InputLabel>
						<FormInput
							name="remarks"
							type="text"
							style={{ width: 100 }}
							placeholder={`Remarks...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: `Please Enter the name `,
								},
							}}
							inputProps={{
								...register('remarks', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Departure Date:</InputLabel>
						<FormControl fullWidth required>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('departureDate')}
									onChange={(date: any) => setValue('departureDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('departureDate', {
												required: 'From Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>

							{errors?.fromDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.fromDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Arrival Date:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('arrivalDate')}
									onChange={(date: any) => setValue('arrivalDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('arrivalDate', {
												required: 'Ariival Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.toDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.toDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Departure Time:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="Departure Time"
									value={getValues('departureTime')}
									onChange={(time: any) => setValue('departureTime', time)}
									slotProps={{
										textField: {
											size: 'small',
											...register('departureTime', {
												required: 'Departure time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.departureTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.departureTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Arrival Time at Destination:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="Arrival Time"
									value={getValues('arrivalTime')}
									onChange={(time: any) => setValue('arrivalTime', time)}
									slotProps={{
										textField: {
											size: 'small',
											...register('arrivalTime', {
												required: 'Arrival Time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.departureTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.departureTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Country:</InputLabel>
						<Controller
							control={control}
							name="country"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Country..`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('country'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Country is required` }}
						/>

						{errors?.country?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.country?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Cost Centre:</InputLabel>
						<Controller
							control={control}
							name="costCentre"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Cost Centre..`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('costCentre'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `costCentre type is required` }}
						/>

						{errors?.costCentre?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.costCentre?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Traveller Type:</InputLabel>
						<Controller
							control={control}
							name="travellerType"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Traveller  Type..`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('travellerType'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `travellerTypeis required` }}
						/>

						{errors?.travellerType?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.travellerType?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Ticket Booked By:</InputLabel>
						<Controller
							control={control}
							name="ticketBookedBy"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Ticket Booked By..`}
									value={
										regularizationTypes.find(
											(option) =>
												option.id === parseInt(watch('ticketBookedBy'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Ticked Booked by  is required` }}
						/>

						{errors?.ticketBookedBy?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.ticketBookedBy?.message}</>
							</Typography>
						) : null}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Departure City:</InputLabel>
						<Controller
							control={control}
							name="departureCity"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Departure City..`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('departureCity'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Departure City is required` }}
						/>

						{errors?.country?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.country?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Destination City:</InputLabel>
						<Controller
							control={control}
							name="destinationCity"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Destination City..`}
									value={
										regularizationTypes.find(
											(option) =>
												option.id === parseInt(watch('destinationCity'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Destination City is required` }}
						/>

						{errors?.costCentre?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.costCentre?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Mode:</InputLabel>
						<Controller
							control={control}
							name="mode"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Mode..`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('mode'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Mode is required` }}
						/>

						{errors?.mode?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.mode?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
						<InputLabel>Class:</InputLabel>
						<Controller
							control={control}
							name="class"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Class...`}
									value={
										regularizationTypes.find(
											(option) => option.id === parseInt(watch('class'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Class  is required` }}
						/>

						{errors?.ticketBookedBy?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.ticketBookedBy?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} p={2}>
						<CustomButton
							type="button"
							text={`Add `}
							startIcon={AddIcon}
							className=" px-10 py-5 my-5"
							fontSize={14}
							onClick={handleAddFamily}
							color={'primary'}
						/>
					</Grid>
					{fields.map((item: any, index: number) => (
						<TravelRequsitionFormComponent
							index={index}
							control={control}
							errors={errors}
							setValue={setValue}
							register={register}
							remove={remove}
							getValues={getValues}
							watch={watch}
							trigger={trigger}
							handleAddFamily={handleAddFamily}
						/>
					))}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<InputLabel>Purpose of Travel:</InputLabel>
						<FormInput
							hideCountButton
							// multipleLine

							name="purposeOfTravel"
							type="text"
							placeholder={`Purpose of travel ...`}
							control={control}
							rules={{ required: `Purpose of travel is required` }}
							className="input-from-text"
							inputProps={{
								...register('purposeOfTravel', {
									required: `Purpose of travel is required`,
								}),
							}}
						/>
						<>{console.log('errors', errors)}</>
						{errors?.purposeOfTravel?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.purposeOfTravel?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<InputLabel>Reason:</InputLabel>
						<FormInput
							hideCountButton
							// multipleLine
							name="reason"
							type="text"
							placeholder={`Reason`}
							control={control}
							className="input-from-text"
							inputProps={{
								...register('reason', { required: `Reason is required` }),
							}}
						/>
						{errors?.reason?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.reason?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12}>
						<InputLabel>Tour Type:</InputLabel>
						<RadioGroup
							row
							aria-label="tourType"
							name="tourType"
							value={tourType}
							onChange={handleTourTypeChange}
						>
							<FormControlLabel
								value="domestic"
								control={<Radio />}
								label="Domestic Travel"
							/>
							<FormControlLabel
								value="international"
								control={<Radio />}
								label="International Travel"
							/>
						</RadioGroup>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={accommodationRequired}
									onChange={handleAccommodationChange}
									name="accommodationRequired"
								/>
							}
							label="Accommodation Required"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={travelAdvanceRequested}
									onChange={handleTravelAdvanceChange}
									name="travelAdvanceRequested"
								/>
							}
							label="Request for Travel Advance"
						/>
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={`${t('Reset')}`}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text="Submit"
							className="p-12 secondary-button"
						/>
						<CustomButton
							type="button"
							text={`Save`}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default TravelRequisitionForm;
