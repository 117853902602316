import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './generator.scss';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import theme from './common/theme';
import ApiModal from './common/ui/ApiModal';
import store from '../src/store/store';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<>
		{/* <React.StrictMode> */}
		<Provider store={store}>
			<Suspense>
				<ThemeProvider theme={theme}>
					<App />
					<ApiModal />
				</ThemeProvider>
			</Suspense>
		</Provider>
		{/* </React.StrictMode> */}
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
