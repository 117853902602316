import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './dashboard.scss';

import { useTranslation } from 'react-i18next';



function DashboardScreen() {
	const [tableRow, setTableRow] = useState([]);
	const { t } = useTranslation();
	
	const userInfo = localStorage.getItem('userInfo');


	const columns = [
		{
			headerName: t('FULL_NAME'),
			field: 'full_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('EMAIL'),
			field: 'email',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('MOBILE_NUMBER'),
			field: 'mobile',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('ADDRESS'),
			field: 'address',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},

		{
			headerName: t('CANDIDATE_NAME'),
			field: 'candidate_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			field: 'subject',
			headerName: t('SUBJECT'),
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('DESCRIPTION'),
			field: 'description',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('STATUS'),
			field: 'status_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
	];

	const handlePagination = (data: any) => {
		
	};
	useEffect(() => {
		// const payload = { petetion_id: 'd53d88a5-3a96-4808-92ac-5a5131184418' };
	
	}, []);
	return (
		<div>
			<Grid
				item
				xs={12}
				container
				display="flex"
				// justifyContent="space-around"
				className="dashboard-grid"
			>
				
			</Grid>
		</div>
	);
}

export default DashboardScreen;
