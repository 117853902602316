import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import SvgConverter from '../../../common/SvgConverter';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
// import { bloodGroup, gender } from '../../../common/constants/dropDownList';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
// import UserProfileImage from '../../../common/UserProfileImage';
import Person2Icon from '../../../common/icons/profile-png-5.png';
import { useDispatch } from 'react-redux';
// import { getProfileInProgress, updateUserInProgress } from '../../../common/redux/user/action/userAction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserProfileImage from '../../../common/UserProfileImage';
import { gender } from '../../../common/constants/dropDownList';

const EditProfile = ({
  text,
  handleSubmit,
  register,
  reset,
  errors,
  control,
  setValue,
  watch,
}: {
  text: any;
  handleSubmit: any;
  register: any;
  reset: any;
  errors: any;
  control: any;
  setValue: any;
  watch: any;
}) => {
const{t}=useTranslation()
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch()
  const  {user}= useSelector((state:any) => state);
 
  
  const userData = user?.getProfile
  ?.data?.data || [];   
  

  const onSubmit = (data: any) => {
   
    
    
  };
  
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          justifyContent='center'
          // className='login-form'
        >
          <Grid container xs={12} p={2}>
            <Grid item xs={6} alignItems={'center'} display={'flex'}>
              <Typography
                fontSize={20}
                fontWeight={600}
                color={theme.palette.common.black}
              >
                {text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            >
             
            </Grid>
          </Grid>
          <Grid item xs={12} p={2} justifyContent={'center'} display={'flex'}>
            <UserProfileImage
              control={control}
              register={register}
              errors={errors}
              image={Person2Icon}
              setValue={setValue}
              watch={watch}
              defaultValue={userData}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`First Name..`}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='firstName'
              type='text'
              placeholder={`First Name.....`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('firstName', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                firstName||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`Last Name`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='lastName'
              type='text'
              placeholder={`Last Name...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message:`${t('PLEASE_ENTER_LAST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('lastName', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                lastName||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
             label={`${t('EMAIL')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='email'
              type='text'
              placeholder={`${t('EMAIL')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                    value: true,
                    message: `${t('PLEASE_ENTER_EMAIL')}`,
                },
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: `${t('INVALID_EMAIL_ADDRESS')}`,
                },
            }}
              inputProps={{
                ...register('email', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                email||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('MOBILE_NUMBER')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='phone'
              type='text'
              placeholder={`${t('MOBILE_NUMBER')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                    value: true,
                    message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                },
                maxLength: {
                    value: 10,
                    message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
              },
              minLength: {
                value: 10,
                message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
            },
            }}
              inputProps={{
                ...register('phone', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                phone||""}
            />
          </Grid>
        
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('DOB')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dob'
              type='date'
              placeholder={`${t('DOB')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('PLEASE_ENTER_DOB')}`,
                },
              }}
              inputProps={{
                ...register('dob', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                dob||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Gender')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={gender}
              ClassName='form-select'
              placeholder={`Gender...`}
              inputProps={register('gender_type', {
                required: {
                  value: true,
                  message: `${t('Select_Gender')}`,
                },
              })}
              name={'gender_type'}
              error={errors}
              defaultValue={userData?.
                gender_type||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('ADDRESS')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              hideCountButton
              multipleLine
              name='address'
              type='text'
              placeholder={`${t('ADDRESS')}...`}
              control={control}
              className='input-from-text'
            
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
              defaultValue={userData?.address||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            
          </Grid>
          
          
          <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text={`Update Profile`}
              color={'secondary'}
              className='p-12'
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditProfile;
