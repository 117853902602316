import {
	FormControl,
	Grid,
	InputLabel,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../dashboard.scss';

type FormData = {
	misselaneous: {
		missellaneous_date: any;
		missellaneous_expenses_type: any;
		missellaneous_amount: any;
		particulars: any;
		missellaneous_document: any;
	}[];
};
const MisselaneousForm: React.FC<{
	setMisselaneousTotal: (value: number) => void;
	misselaneousTotal?: any;
	register?: any;
	errors?: any;
	control?: any;
}> = ({
	setMisselaneousTotal,
	misselaneousTotal,
	register,
	errors,
	control,
}) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [calculateMessilaneous, setCalculateMessilaneous] = useState(false);
	const { setValue, getValues, watch } = useForm<FormData>({
		defaultValues: {
			misselaneous: [
				{
					missellaneous_date: null,
					missellaneous_expenses_type: '',
					missellaneous_amount: '',
					particulars: '',
					missellaneous_document: '',
				},
			],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'misselaneous',
	});

	const handleAddExpenses = () => {
		append({
			missellaneous_date: '',
			missellaneous_expenses_type: '',
			missellaneous_amount: '',
			particulars: '',
			missellaneous_document: '',
		});
	};

	const handleExpenses = (index: number, value: any) => {
		setValue(`misselaneous.${index}.missellaneous_expenses_type`, value, {
			shouldValidate: true,
		});
	};

	const expensesList = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];
	// useEffect(() => {
	// 	let total = 0;
	// 	const fields = getValues('misselaneous');
	// 	fields?.forEach((field, index) => {
	// 		total += parseFloat(field.missellaneous_amount) || 0;
	// 	});
	// 	console.log('missellaneous_amount', total);
	// 	setMisselaneousTotal(total);
	// }, [fields, getValues, setMisselaneousTotal, watch, calculateMessilaneous]);
	const calculateTotal = () => {
		let total = 0;
		const fields = getValues('misselaneous');
		fields?.forEach((field, index) => {
			total += parseFloat(field.missellaneous_amount) || 0;
		});
		console.log('missellaneous_amount', total);
		setMisselaneousTotal(total);
		// calculateTotal();
	};
	const handleMisselanous = (event: any, index: any) => {
		let total = 0;

		const fields = getValues('misselaneous');
		// fields?.forEach((field, index) => {
		// 	total += parseFloat(field.missellaneous_amount) || 0;
		// });

		console.log('missellaneous_amount', event?.target?.value);
		setMisselaneousTotal(Number(total));
		// calculateTotal();

		setValue(
			`misselaneous.${index}.missellaneous_amount`,
			event?.target?.value
		);
		fields.forEach((expense: any, index: number) => {
			total += Number(expense.total_amount);
		});
		setMisselaneousTotal(Number(total));
	};
	useEffect(() => {
		let total = 0;

		const fields = getValues('misselaneous');

		fields.forEach((expense: any, index: number) => {
			total += Number(expense.total_amount || 0);
		});
		setMisselaneousTotal(Number(total));
	}, [[watch('misselaneous')]]);
	useEffect(() => {
		handleAddExpenses();
	}, []);
	return (
		<Grid>
			<Grid
				container
				display="flex"
				// justifyContent="center"
				// className='login-form'
			>
				<Grid container spacing={2}>
					{fields.map((field, index) => (
						<React.Fragment key={field.id}>
							<Grid
								item
								xs={12}
								p={2}
								display={'flex'}
								justifyContent={'space-between'}
							>
								<Typography
									fontSize={20}
									fontWeight={600}
									color={theme.palette.info.dark}
								>
									{t('MISSELLANEOUS_DETAILS')}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<InputLabel> Date:</InputLabel>
								<FormControl fullWidth required>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={getValues(
												`misselaneous.${index}.missellaneous_date`
											)}
											onChange={(date: any) =>
												setValue(
													`misselaneous.${index}.missellaneous_date`,
													date
												)
											}
											slotProps={{
												textField: {
													size: 'small',
													...register(
														`misselaneous.${index}.missellaneous_date`,
														{
															required: 'Date is required',
														}
													),
												},
											}}
										/>
									</LocalizationProvider>

									{errors?.misselaneous?.[index]?.missellaneous_date && (
										<Typography color={theme.palette.error.main} fontSize={12}>
											{
												errors?.misselaneous?.[index]?.missellaneous_date
													?.message
											}
										</Typography>
									)}
								</FormControl>
							</Grid>{' '}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('EXPENSES_TYPE')}
									justifyContent="flex-start"
									labelProps={{ color: 'black' }}
								/>
								<Controller
									control={control}
									name={`misselaneous.${index}.missellaneous_expenses_type`}
									render={({ field }) => (
										<Select
											options={expensesList}
											isSearchable
											placeholder={t('SELECT_EXPENSES_TYPE')}
											value={
												expensesList.find(
													(option) =>
														option.id ===
														parseInt(
															watch(
																`misselaneous.${index}.missellaneous_expenses_type`
															)
														)
												) || null
											}
											getOptionLabel={(option) => `${option.name}`}
											getOptionValue={(option) => option.id}
											onChange={(selectedOption) => {
												handleExpenses(index, selectedOption?.id || '');
												field.onChange(selectedOption?.id || '');
											}}
											onBlur={() => field.onBlur()}
											isClearable
											defaultValue={location.state?.data?.role_id}
										/>
									)}
									rules={{ required: t('EXPENSES_TYPE_IS_REQUIRED') }}
								/>

								{errors?.misselaneous?.[index]?.missellaneous_expenses_type
									?.message ? (
									<Typography color={theme.palette.error.main} fontSize={12}>
										<>
											{
												errors?.misselaneous?.[index]
													?.missellaneous_expenses_type?.message
											}
										</>
									</Typography>
								) : null}
							</Grid>{' '}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('AMOUNT')}
									labelProps={{ color: 'black' }}
									justifyContent="flex-start"
								/>
								<FormInput
									name={`misselaneous.${index}.missellaneous_amount`}
									type="number"
									placeholder={`${t('AMOUNT')}...`}
									control={control}
									// className="input-from-text"
									inputProps={{
										...register(
											`misselaneous.${index}.missellaneous_amount`,
											{}
										),
										onChange: (event: any) => handleMisselanous(event, index),
										// setCalculateMessilaneous(true),
									}}
									errors={errors}
									rules={{
										required: {
											value: true,
											message: `${t('AMOUNT_IS_REQUIRED')}`,
										},
									}}
								/>
								{errors?.misselaneous?.[index]?.missellaneous_amount
									?.message ? (
									<Typography color={theme.palette.error.main} fontSize={12}>
										<>
											{
												errors?.misselaneous?.[index]?.missellaneous_amount
													?.message
											}
										</>
									</Typography>
								) : null}
							</Grid>{' '}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('PARTICULARS')}
									labelProps={{ color: 'black' }}
									justifyContent="flex-start"
								/>
								<FormInput
									name={`misselaneous.${index}.particulars`}
									type="text"
									placeholder={`${t('PARTICULARS')}...`}
									control={control}
									// className="input-from-text"
									inputProps={{
										...register(`misselaneous.${index}.particulars`, {}),
									}}
									errors={errors}
									rules={{
										required: {
											value: true,
											message: `${t('PARTICULARS_IS_REQUIRED')}`,
										},
									}}
								/>{' '}
								{errors?.misselaneous?.[index]?.particulars?.message ? (
									<Typography color={theme.palette.error.main} fontSize={12}>
										<>{errors?.misselaneous?.[index]?.particulars?.message}</>
									</Typography>
								) : null}
							</Grid>{' '}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={'Upload Document'}
									labelProps={{ color: 'black' }}
									justifyContent="flex-start"
								/>
								<FormInput
									name={`misselaneous.${index}.missellaneous_document`}
									type="file"
									placeholder={'UPLOAD DOCUMEMT'}
									control={control}
									// className="input-from-text"
									inputProps={{
										...register(
											`misselaneous.${index}.missellaneous_document`,
											{}
										),
									}}
									errors={errors}
									rules={{
										required: {
											value: true,
											message: `${t('UPLOAD_DOCUMENT_IS_REQUIRED')}`,
										},
									}}
								/>{' '}
								{errors?.misselaneous?.[index]?.missellaneous_document
									?.message ? (
									<Typography color={theme.palette.error.main} fontSize={12}>
										<>
											{
												errors?.misselaneous?.[index]?.missellaneous_document
													?.message
											}
										</>
									</Typography>
								) : null}
							</Grid>
							<Grid item xs={12} p={2}>
								{index !== 0 && (
									<Grid item xs={12} p={2}>
										<CustomButton
											type="button"
											text={`Remove `}
											className=" px-10 py-5 my-5 secondary-button"
											onClick={() => remove(index)}
										/>
									</Grid>
								)}
							</Grid>
						</React.Fragment>
					))}
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
					<CustomButton
						type="button"
						text={`Add `}
						className=" px-10 py-5 my-5 primary-button"
						fontSize={14}
						onClick={() => handleAddExpenses()}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MisselaneousForm;
