import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';
import LeaveHeader from './LeaveHeader';

function LeaveHistory() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState([]);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePageChange = (page: any) => {};

	const handleDelete = () => {
		setOpen(true);
	};
	const handleDeleteAndSetId = (id: any) => {
		handleDelete();
		setId(id);
	};
	const columns: any = [
		{
			headerName: 'Req.No',
			field: 'req_no',
			width: 200,
		},
		{
			headerName: 'Request Date',
			field: 'request_date',
			width: 200,
		},
		{
			headerName: 'Leave Type',
			field: 'leave_type',
			width: 200,
		},
		{
			headerName: 'From Date',
			field: 'from_date',
			width: 200,
		},
		{
			headerName: 'To Date',
			field: 'to_date',
			width: 200,
		},
		{
			headerName: 'No.of days',
			field: 'days',
			width: 200,
		},
		{
			headerName: 'User Status',
			field: 'user_status',
			width: 200,
		},
		{
			headerName: 'Approval Status',
			field: 'approval_status',
			width: 250,
		},
	];
	const rows: any = [
		{
			id: 1,
			req_no: 'REQ001',
			request_date: '5-5-2024',
			leave_type: 'Sick leave',
			attendance_time: '10:00 AM',
			contact_number: '90877676666',
			from_date: '8-5-2024',
			to_date: '9-5-2024',
			days: '2',
			user_status: 'submitted',
			approval_status: 'approved by pandita',
		},
		{
			id: 2,
			req_no: 'REQ001',
			request_date: '5-5-2024',
			leave_type: 'Sick leave',
			attendance_time: '10:00 AM',
			contact_number: '90877676666',
			from_date: '8-5-2024',
			to_date: '9-5-2024',
			days: '2',
			user_status: 'submitted',
			approval_status: 'approved by pandita',
		},
	];

	return (
		<Grid container>
			<Grid xs={12} mb={5}>
				<Grid
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					className="donation-header"
				>
					<Typography fontSize={20} fontWeight={700}>
						Leave History
					</Typography>

					<>
						{/* <CustomButton
							type="button"
							text={`Add  Leave`}
							startIcon={AddIcon}
							className=" px-10 py-5 my-5"
							fontSize={14}
							onClick={() => {
								navigate(APP_ROUTES.ADD_LEAVE);
							}}
							color={'primary'}
						/> */}
					</>
				</Grid>
			</Grid>
			<Grid
				item
				container
				display="flex"
				alignItems={'center'}
				mb={2}
				justifyContent={'space-around'}
				// className='login-form'
				spacing={1}
			>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Req.No:</Typography>
					<TextField
						label={'Req.no'}
						size="small"
						variant="outlined"
						//   value={searchQuery}
						//   onChange={handleSearchChange}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Leave Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid
					item
					xs={10}
					sm={7}
					md={12}
					lg={3}
					xl={3}
					display={'flex'}
					spacing={2}
					container
				>
					<Grid item>
						<CustomButton
							type="button"
							text={t('SEARCH')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>{' '}
					<Grid item>
						<CustomButton
							type="button"
							text={t('EXPORT_TO_EXCEL')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					totalRecords={rows.length}
					handlePagination={handlePaginationChange}

					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>
		</Grid>
	);
}
export default LeaveHistory;
