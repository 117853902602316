import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
	Grid,
	Typography,
	useTheme,
	InputLabel,
	FormControl,
	TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';

import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const TravelRequsitionFormComponent = ({
	control,
	errors,
	setValue,
	index,
	register,
	remove,

	watch,
	trigger,
	getValues,
	handleAddFamily,
}: {
	control: any;
	errors: any;
	setValue: any;
	index: any;
	register: any;
	remove: any;

	watch?: any;
	trigger?: any;
	handleAddFamily?: any;
	getValues?: any;
}) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);

	const handleReset = () => {
		// setValue('phone', '');
		// setValue('regularizationType', '');
		// setValue('place', '');
		// setValue('reason', '');
	};

	const handleCategory = (value: any) => {
		// setValue('regularizationType', value, {
		// 	shouldValidate: true,
		// });
	};

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		console.log('data', data);

		// navigate(APP_ROUTES.TEAM_MEMBER);
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	const regularizationTypes = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
		// Add more types as needed
	];

	return (
		<Grid>
			<Grid
				container
				display="flex"
				justifyContent="center"
				// className='login-form'
			>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>From Date:</InputLabel>
					<FormControl fullWidth required>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={getValues(`subForm.[${index}].fromDate`)}
								onChange={(date: any) =>
									setValue(`subForm.[${index}].fromDate`, date)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].fromDate`, {
											required: 'From Date is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>

						{errors?.subForm?.[index].fromDate && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm[index].fromDate?.message}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>To Date:</InputLabel>
					<FormControl fullWidth>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={getValues(`subForm.[${index}].toDate`)}
								onChange={(date: any) =>
									setValue(`subForm.[${index}].toDate`, date)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].toDate`, {
											required: 'To Date is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>
						{errors?.subForm?.[index].toDate && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm?.[index].toDate?.message}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Name:</InputLabel>
					<FormInput
						name={`subForm.[${index}].name`}
						type="text"
						style={{ width: 100 }}
						placeholder={`...`}
						control={control}
						className="input-from"
						rules={{
							required: {
								value: true,
								message: `Please Enter the name `,
							},
						}}
						inputProps={{
							...register(`subForm.[${index}].name`, {}),
						}}
						errors={errors}
					/>
				</Grid>

				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Remarks:</InputLabel>
					<FormInput
						name={`subForm.[${index}].remarks`}
						type="text"
						style={{ width: 100 }}
						placeholder={`Remarks...`}
						control={control}
						className="input-from"
						rules={{
							required: {
								value: true,
								message: `Please Enter the name `,
							},
						}}
						inputProps={{
							...register(`subForm.[${index}].remarks`, {}),
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Departure Date:</InputLabel>
					<FormControl fullWidth required>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={getValues(`subForm.[${index}].departureDate`)}
								onChange={(date: any) =>
									setValue(`subForm.[${index}].departureDate`, date)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].departureDate`, {
											required: 'From Date is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>

						{errors?.subForm?.[index].departureDate && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm?.[index].departureDate?.message}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Arrival Date:</InputLabel>
					<FormControl fullWidth>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={getValues(`subForm.[${index}].arrivalDate`)}
								onChange={(date: any) =>
									setValue(`subForm.[${index}].arrivalDate`, date)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].arrivalDate`, {
											required: 'Ariival Date is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>
						{errors?.subForm?.[index].arrivalDate && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm?.[index].arrivalDate}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Departure Time:</InputLabel>
					<FormControl fullWidth>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								label="Departure Time"
								value={getValues(`subForm.[${index}].departureTime`)}
								onChange={(time: any) =>
									setValue(`subForm.[${index}].departureTime`, time)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].departureTime`, {
											required: 'Departure time is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>
						{errors?.subForm?.[index].departureTime && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm?.[index].departureTime?.message}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Arrival Time at Destination:</InputLabel>
					<FormControl fullWidth>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								label="Arrival Time"
								value={getValues(`subForm.[${index}].arrivalTime`)}
								onChange={(time: any) =>
									setValue(`subForm.[${index}].arrivalTime`, time)
								}
								slotProps={{
									textField: {
										size: 'small',
										...register(`subForm.[${index}].arrivalTime`, {
											required: 'Arrival Time is required',
										}),
									},
								}}
							/>
						</LocalizationProvider>
						{errors?.subForm?.[index].arrivalTime && (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors?.subForm?.[index].arrivalTime?.message}
							</Typography>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Country:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].country`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Country..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].country`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Country is required` }}
					/>

					{errors?.subForm?.[index].country?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].country?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Cost Centre:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].costCentre`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Cost Centre..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].costCentre`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `CostCentre is required` }}
					/>

					{errors?.subForm?.[index].costCentre?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].costCentre?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Traveller Type:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].travellerType`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Traveller  Type..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].travellerType`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Traveller type is required` }}
					/>

					{errors?.subForm?.[index].travellerType?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].travellerType?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Ticket Booked By:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].ticketBookedBy`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Ticket Booked By..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].ticketBookedBy`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Ticked Booked by  is required` }}
					/>

					{errors?.subForm?.[index].ticketBookedBy?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].ticketBookedBy?.message}</>
						</Typography>
					) : null}
				</Grid>

				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Departure City:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].departureCity`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Departure City..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].departureCity`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Departure City is required` }}
					/>

					{errors?.subForm?.[index].departureCity?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].departureCity?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Destination City:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].destinationCity`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Destination City..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id ===
											parseInt(watch(`subForm.[${index}].destinationCity`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Destination City is required` }}
					/>

					{errors?.subForm?.[index].destinationCity?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].destinationCity?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Mode:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].mode`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Mode..`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id === parseInt(watch(`subForm.[${index}].mode`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Mode is required` }}
					/>

					{errors?.subForm?.[index].mode?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].mode?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={3} xl={6} p={2}>
					<InputLabel>Class:</InputLabel>
					<Controller
						control={control}
						name={`subForm.[${index}].class`}
						render={({ field }) => (
							<Select
								options={regularizationTypes}
								isSearchable
								placeholder={`Class...`}
								value={
									regularizationTypes.find(
										(option) =>
											option.id === parseInt(watch(`subForm.[${index}].class`))
									) || null
								}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => option.id}
								onChange={(selectedOption) => {
									handleCategory(selectedOption?.id || '');
									field.onChange(selectedOption?.id || '');
								}}
								onBlur={() => field.onBlur()}
								isClearable
								defaultValue={location.state?.data?.role_id}
							/>
						)}
						rules={{ required: `Class  is required` }}
					/>

					{errors?.subForm?.[index].class?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.subForm?.[index].class?.message}</>
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} p={2}>
					<CustomButton
						startIcon={CloseIcon}
						type="button"
						text={`Remove `}
						className=" px-10 py-5 my-5"
						onClick={() => {
							remove(index);
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TravelRequsitionFormComponent;
