import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';

import LoginForm from './LoginForm';
import './Login.scss';
import SignupForm from './SignupForm';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Login.scss';
import person from '../../common/icons/40.png';
import person1 from '../../common/icons/52.png';
import { useSelector } from 'react-redux';
import Person2Icon from '../../common/icons/80 (1).png';

const Signup = () => {
	const theme = useTheme();
	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 200,
		nextArrow: <></>,
	};

	return (
		
		<Grid
		item
	
		justifyContent="center"
		display={'flex'}
		alignItems={'center'}
		flexDirection={"column"}
	
		
	>
		<div className='login-logo'>
		<img src={Person2Icon} alt="logo" className="img-logo-login" />
		</div>

		<SignupForm />
	</Grid>
	);
};
export default Signup;
