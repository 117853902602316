/* eslint-disable react/require-default-props */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { FC } from 'react';
import { FormHelperText } from '@mui/material';
import theme from '../../../../theme';

interface RadioOption {
	value: string;
	label: string;
}

interface Props {
	name: string;
	options: RadioOption[] | any;
	value: string;
	// eslint-disable-next-line no-unused-vars
	onChange: (value: string) => void;
	inputProps?: any;
	errors?: any;
	errorTextClassName?: string | undefined;
	size?: 'small' | 'medium';
	inputClassName?: any;
}

const CustomRadioGroup: FC<Props> = ({
	name,
	options,
	value,
	onChange,
	inputProps = {},
	errors = {},
	errorTextClassName = '',
	size = 'medium',
	inputClassName,
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
	};

	return (
		<FormControl>
			<RadioGroup name={name} value={value} onChange={handleChange} row>
				{options.map((option: any) => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						control={<Radio size={size} inputProps={inputProps} />}
						label={option.label}
						className={inputClassName}
					/>
				))}
			</RadioGroup>
			{errors?.[inputProps.name]?.message && (
				<FormHelperText
					className={`error-text ${errorTextClassName}`}
					error={false}
					sx={{ color: theme.palette.error.dark }}
				>
					{errors?.[inputProps.name]?.message}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default CustomRadioGroup;
