import { Grid, InputLabel, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller } from 'react-hook-form';
import ManagaTravelHeader from './ManagaTravelHeader';

function ManageTravelTable() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [tableRow, setTableRow] = useState([]);
	const [selectedRegularizationType, setSelectedRegularizationType] =
		useState(null);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};

	const columns: any = [
		{
			headerName: 'Req.no',
			field: 'reqNo',
			width: 200,
		},

		{
			headerName: 'Name',
			field: 'name',
			width: 300,
		},
		{
			headerName: 'Department',
			field: 'departmentName',
			width: 300,
		},
		{
			headerName: 'Tour Type',
			field: 'tourType',
			width: 250,
		},
		{
			headerName: 'Tour From Date',
			field: 'tourFromDate',
			width: 200,
		},
		{
			headerName: 'Tour To Date',
			field: 'tourToDate',
			width: 200,
		},
		{
			headerName: 'Requested Date',
			field: 'requestedDate',
			width: 200,
		},
	];
	const rows: any = [
		{
			id: 1,
			reqNo: 'REQ001',
			name: 'Marimuthu N(T00066)',
			departmentName: 'Advanced Radiology',
			tourType: 'DOMESTIC TRAVEL',
			tourFromDate: '28/02/2024',
			tourToDate: '28/02/2024',
			requestedDate: '27/02/2024',
		},
		{
			id: 2,
			reqNo: 'REQ002',
			name: 'Muthu N(T00066)',
			departmentName: 'Advanced Radiology',
			tourType: 'DOMESTIC TRAVEL',
			tourFromDate: '28/02/2024',
			tourToDate: '28/02/2024',
			requestedDate: '27/02/2024',
		},
		{
			id: 3,
			reqNo: 'REQ003',
			name: 'Mari N(T00066)',
			departmentName: 'Advanced Radiology',
			tourType: 'DOMESTIC TRAVEL',
			tourFromDate: '28/02/2024',
			tourToDate: '28/02/2024',
			requestedDate: '27/02/2024',
		},
	];
	const handleCategory = (value: any) => {
		setSelectedRegularizationType(value);
	};

	const regularizationTypes = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
		// Add more types as needed
	];

	// Pass this 'rows' array as a prop to your TableComponent

	return (
		<Grid container>
			<Grid xs={12} mb={5}>
				<ManagaTravelHeader />
			</Grid>
			<Grid
				container
				display="flex"
				alignItems={'center'}
				mb={2}

				// className='login-form'
			>
				<Grid xs={12} sm={6} md={4} lg={4} xl={2} mb={3} mr={5}>
					<InputLabel>Requisition No:</InputLabel>
					<Select
						options={regularizationTypes}
						isSearchable
						placeholder={`Requisition No..`}
						value={selectedRegularizationType}
						onChange={(selectedOption) => handleCategory(selectedOption?.id)}
						isClearable
					/>
				</Grid>

				<Grid xs={12} sm={4} md={4} lg={4} xl={2}>
					<CustomButton text={'Find'} className="px-5 py-3 primary-button" />
				</Grid>
			</Grid>
			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					hideFooterPagination={true}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					totalRecords={rows.length}
					handlePagination={handlePaginationChange}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>
			<Grid
				container
				display="flex"
				alignItems={'center'}
				mb={2}
				// justifyContent={'space-between'}
				// className='login-form'
				spacing={2}
			>
				<Grid item xs={6} sm={6} md={4} lg={4} xl={4} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid item xs={6} sm={6} md={4} lg={4} xl={4} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>

				<Grid xs={10} sm={9} md={4} lg={3} xl={4} item container spacing={2}>
					<Grid item>
						<CustomButton
							text={'Extend Date'}
							className="px-5 secondary-button"
						/>
					</Grid>
					<Grid item>
						<CustomButton
							text={'Cancel Request'}
							className="px-5 primary-button "
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default ManageTravelTable;
