import { Grid, TableRow, TextField, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { useState } from 'react';

const AttendanceCalender = () => {
	const [selectedYear, setSelectedYear] = useState('');
	const StyledTableHead = withStyles((theme) => ({
		root: {
			backgroundColor: 'white', // Set background color for the table head
		},
	}))(MuiTableHead);

	const StyledTableCell = withStyles((theme) => ({
		root: {
			color: 'black', // Set text color for table cells
			border: '1px solid #ccc', // Add border style
			padding: theme.spacing(1), // Add padding for inner spacing
		},
	}))(TableCell);

	function createData(
		LeaveType: any,
		PrevYear: any,
		CurrentYear: any,
		Availed: any,
		Encashed: any,
		Approval: any,
		Balance: any,
		Entitlement: any
	) {
		return {
			LeaveType,
			PrevYear,
			CurrentYear,
			Availed,
			Encashed,
			Approval,
			Balance,
			Entitlement,
		};
	}

	const rows = [
		createData('Earned Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Casual Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Sick Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Comp Off', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Marriage Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Paternity Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Study Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('Beraevement Leave', 159, 6.0, 24, 4.0, 6.0, 24, 56),
		createData('LWP', 159, 6.0, 24, 4.0, 6.0, 24, 56),
	];
	const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedYear(event.target.value); // Update selected year state
	};

	return (
		<>
			<div style={{ overflowX: 'auto', minWidth: 300 }}>
				<Grid item xs={12} spacing={2}>
					{/* <Grid item xs={6} sm={6} md={6} lg={6}> */}
					{/* <Grid item xs={12}display={"flex"} justifyContent={"space-between"} className='calender-header'>
                        <Typography variant="h4">
                            Leave Calendar
                        </Typography>
                        <TextField
                            label="Select Year"      
                            variant="outlined"
                            value={selectedYear}
                            onChange={handleYearChange}
                            className='year-select-box'
                        />
                    </Grid> */}

					<StyledTableHead>
						<TableRow>
							<StyledTableCell>Leave Type</StyledTableCell>
							<StyledTableCell align="right">
								Carry Forwarded From Prev Year
							</StyledTableCell>
							<StyledTableCell align="right">
								Credited in Current Year
							</StyledTableCell>
							<StyledTableCell align="right">
								Availed in Current Year
							</StyledTableCell>
							<StyledTableCell align="right">
								Encashed in Current Year
							</StyledTableCell>
							<StyledTableCell align="right">
								Pending for Approval
							</StyledTableCell>
							<StyledTableCell align="right">
								Balance as on Date
							</StyledTableCell>
							<StyledTableCell align="right">
								Annual Entitlement
							</StyledTableCell>
						</TableRow>
					</StyledTableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.LeaveType}>
								<StyledTableCell component="th" scope="row">
									{row.LeaveType}
								</StyledTableCell>
								<StyledTableCell align="right">{row.PrevYear}</StyledTableCell>
								<StyledTableCell align="right">
									{row.CurrentYear}
								</StyledTableCell>
								<StyledTableCell align="right">{row.Availed}</StyledTableCell>
								<StyledTableCell align="right">{row.Encashed}</StyledTableCell>
								<StyledTableCell align="right">{row.Approval}</StyledTableCell>
								<StyledTableCell align="right">{row.Balance}</StyledTableCell>
								<StyledTableCell align="right">
									{row.Entitlement}
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>

					{/* </Grid> */}
				</Grid>
			</div>
		</>
	);
};

export default AttendanceCalender;
