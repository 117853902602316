/* eslint-disable react/require-default-props */
import * as React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { FC } from 'react';
import './Modal.scss';

interface boxPropsInterface {
	width?: number | undefined;
	height?: number | undefined;
}
interface ModalProps {
	boxProps?: boxPropsInterface | undefined;
	onClose?: () => void;
	open?: any;
	children?: React.ReactNode;
	modalClassName?: string | undefined;
}
const CommonModal: FC<ModalProps> = ({
	boxProps,
	open,
	children,
	onClose,
	modalClassName,
}) => (
	<Modal
		open={open}
		onClose={onClose}
		//   BackdropComponent={Backdrop}  // Set Backdrop component
		// BackdropProps={{
		// 	onClick: undefined, // Disable click on the backdrop
		//   }}
	>
		<Box
			className={`common-style ${modalClassName}`}
			sx={{
				width: boxProps?.width,
				height: boxProps?.height,
				overflowY: 'auto',
				borderRadius: 2,
				scrollbarWidth: 'none',
			}}
		>
			{children}
		</Box>
	</Modal>
);

export default CommonModal;
