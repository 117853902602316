/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTheme } from '@mui/system';
import AuthRoutes from '../Layout/AuthRoutes';
import AppRoutes from '../Layout/AppRoutes';
import { changeCssColors } from '../common/theme';
import './colors.css';
import './App.scss';
import { Provider } from 'react-redux';

import PublicRoutes from '../Layout/PublicRoutes';
import store from '../store/store';

const App = () => {
	const theme = useTheme();
	useEffect(() => {
		changeCssColors(theme);
	}, [theme]);
	const isLogged = useMemo(
		() => !!localStorage.getItem('user'),
		[localStorage.getItem('user')]
	);
	console.log('APPPP', isLogged);
	return (
		<>
			<Provider store={store}>
				<Router>
					<AuthRoutes isLogged={isLogged} />
					<AppRoutes isLogged={isLogged} />
				</Router>
			</Provider>
		</>
	);
};

export default App;
