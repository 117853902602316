import crypt from 'browser-crypto';
import { Buffer } from 'buffer';
import { v4 as UUID } from 'uuid';

const { REACT_APP_CRYPTO_SECRET_KEY } = process.env;
const { REACT_APP_CRYPTO_IV_KEY } = process.env;

class CryptoEncryption {
	constructor() {
		// Constructor
		this.key = Buffer.from(
			'eCo6sjihoAQxtTaE+qSV1VlEDlhILN5R7fREd1BNaWI=',
			'base64'
		);
		this.iv = Buffer.from('5S3a+G4uChnTD+8DcgFHUQ==', 'base64');
	}

	Encrypt(source) {
		const cipher = crypt.createCipheriv(
			'aes-256-cbc',
			this.key,
			this.iv.slice(0, 16)
		);
		let encrypted = cipher.update(JSON.stringify(source), 'utf8', 'base64');
		encrypted += cipher.final('base64');
		return encrypted;
	}

	Decrypt(dataText) {
		const decipher = crypt.createDecipheriv(
			'aes-256-cbc',
			this.key,
			this.iv.slice(0, 16)
		);
		let decrypted = decipher.update(dataText, 'base64');
		decrypted += decipher.final();
		return decrypted;
	}
}

const cryptoEncryptionDecryption = new CryptoEncryption();
export default cryptoEncryptionDecryption;

export const generateUUID = UUID;
