import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
	Grid,
	Typography,
	useTheme,
	InputLabel,
	FormControl,
	TextField,
} from '@mui/material';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';

import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import AttendanceCalender from './AttendanceCalender';

const AttendanceForm = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			fromDate: null,
			toDate: null,
			fromTime: null,
			toTime: null,
			phone: '',
			regularizationType: '',
			place: '',
			reason: '',
		},
	});
	const handleReset = () => {
		setValue('phone', '');
		setValue('regularizationType', '');
		setValue('place', '');
		setValue('reason', '');
	};

	const handleCategory = (value: any) => {
		setValue('regularizationType', value, {
			shouldValidate: true,
		});
	};

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		console.log('data', data);

		// navigate(APP_ROUTES.TEAM_MEMBER);
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	const regularizationTypes = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
		// Add more types as needed
	];

	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
						container 
					>
						<Grid item>
							<Typography
								fontSize={20}
								fontWeight={600}
								color={theme.palette.common.black}
							>
								{location?.state?.type === 'edit'
									? `${t('Edit_Team_Member')}`
									: `Add Attendance`}
							</Typography>
						</Grid>
						<Grid item>
							<CustomButton
								type="button"
								text={`Go Back`}
								startIcon={SubdirectoryArrowRightIcon}
								className="p-8 secondary-button"
								onClick={() => {
									navigate(APP_ROUTES.ATTENDANCE);
								}}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>From Date:</InputLabel>
						<FormControl fullWidth required>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('fromDate')}
									onChange={(date: any) => setValue('fromDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('fromDate', {
												required: 'From Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>

							{errors?.fromDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.fromDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>To Date:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={getValues('toDate')}
									onChange={(date: any) => setValue('toDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('toDate', {
												required: 'To Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.toDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.toDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>From Time:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="From Time"
									value={getValues('fromTime')}
									onChange={(time: any) => setValue('fromTime', time)}
									slotProps={{
										textField: {
											...register('fromTime', {
												required: 'From time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.fromTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.fromTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>To Time:</InputLabel>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="To Time"
									value={getValues('toTime')}
									onChange={(time: any) => setValue('toTime', time)}
									slotProps={{
										textField: {
											...register('toTime', {
												required: 'To time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.toTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.toTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>Mobile:</InputLabel>
						<FormInput
							name="phone"
							type="text"
							style={{ width: 100 }}
							placeholder={`${t('MOBILE_NUMBER')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
								},
								maxLength: {
									value: 10,
									message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
								},
								pattern: {
									value: /^[0-9]*$/,
									message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
								},
								minLength: {
									value: 10,
									message: `${t(
										'MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'
									)}`,
								},
							}}
							inputProps={{
								...register('phone', {}),
							}}
							errors={errors}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<InputLabel>Regularization Type:</InputLabel>
						<Controller
							control={control}
							name="regularizationType"
							render={({ field }) => (
								<Select
									options={regularizationTypes}
									isSearchable
									placeholder={`Regularization type..`}
									value={
										regularizationTypes.find(
											(option) =>
												option.id === parseInt(watch('regularizationType'))
										) || null
									}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => option.id}
									onChange={(selectedOption) => {
										handleCategory(selectedOption?.id || '');
										field.onChange(selectedOption?.id || '');
									}}
									onBlur={() => field.onBlur()}
									isClearable
									defaultValue={location.state?.data?.role_id}
								/>
							)}
							rules={{ required: `Regularization type is required` }}
						/>

						{errors?.regularizationType?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.regularizationType?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<InputLabel>Place:</InputLabel>
						<FormInput
							hideCountButton
							// multipleLine
							name="place"
							type="text"
							placeholder={`Place`}
							control={control}
							rules={{ required: `Place is required` }}
							// className="input-from-text"
							inputProps={{
								...register('place', { required: `Place is required` }),
							}}
						/>
						<>{console.log('errors', errors)}</>
						{errors?.place?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.place?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
						<InputLabel>Reason:</InputLabel>
						<FormInput
							hideCountButton
							// multipleLine
							name="reason"
							type="text"
							placeholder={`Reason`}
							control={control}
							className="input-from-text"
							inputProps={{
								...register('reason', { required: `Reason is required` }),
							}}
						/>
						{errors?.reason?.message ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								<>{errors?.reason?.message}</>
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={`${t('Reset')}`}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text="Submit"
							className="p-12 secondary-button"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AttendanceForm;
