import * as React from 'react';
import { useState } from 'react';
import {
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	Paper,
	TableCell,
	TableSortLabel,
	TextField,
	Grid,
	Typography,
	ListItemSecondaryAction,
} from '@mui/material';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import MuiTableHead from '@material-ui/core/TableHead';
import { AccountCircle } from '@mui/icons-material';
import CustomButton from '../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../../common/ui/TableComponent';
import theme from '../../../common/theme';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useNavigate } from 'react-router-dom';
function createData(
	sno: any,
	leaveType: any,
	remainingBalance: any,
	encashBalance: any,
	remarks: any
) {
	return { sno, leaveType, remainingBalance, encashBalance, remarks };
}

const rows = [createData(1, 'Earned Leave', 15.0, '', '')];

function LeaveEncashmentForm() {
	const formRef = React.useRef<HTMLFormElement>(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			encashBalance: '',
			remarks: '',
		},
	});
	const { t } = useTranslation();
	const [order, setOrder] = useState('asc');
	const navigate = useNavigate();
	const [orderBy, setOrderBy] = useState('sno');
	const [formData, setFormData] = useState({ encashBalance: '', remarks: '' });
  const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};

	const StyledTableHead = withStyles((theme) => ({
		root: {
			backgroundColor: 'white', // Set background color for the table head
		},
	}))(MuiTableHead);

	const StyledTableCell = withStyles((theme) => ({
		root: {
			color: 'black', // Set text color for table cells
			border: '1px solid #ccc', // Add border style
			padding: theme.spacing(1), // Add padding for inner spacing
		},
	}))(TableCell);

	const handleChange = (event: any) => {};

	const onSubmit = (data: any) => {
		// 'data' contains the form values
		console.log('Form Data:', data);
		// Access specific form values like this:
		console.log('Encash Balance:', data.encashBalance);
		console.log('Remarks:', data.remarks);
		// Reset form fields
	};

	const handleReset = () => {
		setFormData({ encashBalance: '', remarks: '' });
	};
	
  const columns: any = [
		{
			headerName: 'Name',
			field: 'name',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Emp Code',
			field: 'empCode',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Department',
			field: 'department',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Designation',
			field: 'designation',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},

		{
			headerName: 'Location',
			field: 'location',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},
		{
			headerName: 'Date of Joining',
			field: 'dateOfJoining',
			sortable: false,
			flex: 1,
			headerClassName: 'header-color',
		},

	];
  const rows1: any = [
		{
      id:1,
			name:"Arun Kumar(TM001)",
			empCode: 'REQ001',

			department: 'Type A',
			requestedDate: '2023-12-10',
			designation: 'Approved',
			location: '2023-12-20',
			dateOfJoining: 'Approved',
		
		},

	];

	return (
		<>
			<Grid>
			<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.common.black}
						>
							Add Leave Encashment
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.LEAVE_ENCASHMENT);
							}}
						/>
					</Grid>{' '}
      <TableComponent
					rows={rows1}
					columns={columns}
					totalRecords={rows.length}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					handlePagination={handlePaginationChange}
				/>
			</Grid>
			<Grid mt={10}>
				<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<StyledTableHead>
								<TableRow>
									<StyledTableCell></StyledTableCell>
									<StyledTableCell>Leave Type</StyledTableCell>
									<StyledTableCell>Remaining Balance</StyledTableCell>
									<StyledTableCell>Encash Balance</StyledTableCell>
									<StyledTableCell>Remarks</StyledTableCell>
								</TableRow>
							</StyledTableHead>
							<TableBody>
								{rows.map((row) => (
									<TableRow key={row.sno}>
										<StyledTableCell>{row.sno}</StyledTableCell>
										<StyledTableCell>{row.leaveType}</StyledTableCell>
										<StyledTableCell>{row.remainingBalance}</StyledTableCell>
										<StyledTableCell>
											<TextField
												id="encashBalance"
												label="Enter the Encash balance...."
												variant="outlined"
												{...register('encashBalance', { required: true })}
												onChange={handleChange}
											/>
										</StyledTableCell>
										<StyledTableCell>
											<TextField
												id="remarks"
												label="Enter the Remarks....."
												variant="outlined"
												{...register('remarks', { required: true })}
												onChange={handleChange}
											/>
										</StyledTableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Grid display={'flex'} justifyContent={'center'} mt={5}>
						<CustomButton
							type="button"
							text={`${t('Reset')}`}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text="Submit"
							className="p-12 secondary-button"
						/>
						{/* <Grid mr={3}>
							<Button variant="contained" onClick={handleReset}>
								Reset
							</Button>
							<Button variant="contained" type="submit">
								Submit
							</Button>
						</Grid> */}
						<Grid></Grid>
					</Grid>
				</form>
			</Grid>
		</>
	);
}

export default LeaveEncashmentForm;
