export const gender = [
  {
    Code: 1,
    Description: 'Male',
    Category: 'Male',
  },
  {
    Code: 2,
    Description: 'Female',
    Category: 'Female',
  },
  {
    Code: 3,
    Description: 'Others',
    Category: 'Others',
  },
];

export const bloodGroup = [
  {
    Code: 1,
    Description: 'O+',
    Category: 'O+',
  },
  {
    Code: 2,
    Description: 'O-',
    Category: 'O-',
  },
  {
    Code: 3,
    Description: 'A+',
    Category: 'A+',
  },
  {
    Code: 4,
    Description: 'A-',
    Category: 'A-',
  },
  {
    Code: 5,
    Description: 'B+',
    Category: 'B+',
  },
  {
    Code: 6,
    Description: 'B-',
    Category: 'B-',
  },
  {
    Code: 7,
    Description: 'AB+',
    Category: 'AB+',
  },
  {
    Code: 8,
    Description: 'AB-',
    Category: 'AB-',
  },
];
export const idProofs = [
  {
    Code: 1,
    Description: 'Pan',
    Category: 'Pan',
  },
  {
    Code: 2,
    Description: 'Aadhar',
    Category: 'Aadhar',
  },
  {
    Code: 3,
    Description: 'DrivingLicense',
    Category: 'DrivingLicense',
  },
  {
    Code: 4,
    Description: 'RationCard',
    Category: 'RationCard',
  },
  {
    Code: 5,
    Description: 'TIN',
    Category: 'TIN',
  },
  {
    Code: 6,
    Description: 'Passport',
    Category: 'B-',
  },
  {
    Code: 7,
    Description: `Elector's Photo,ID No of the Donor`,
    Category: `Elector's Photo,ID No of the Donor`,
  },
  
];

export const familyMember = [
  {
    Code: 1,
    Description: 'Father',
    Category: 'Father',
  },
  {
    Code: 2,
    Description: 'Mother',
    Category: 'Mother',
  },
  {
    Code: 3,
    Description: 'Wife',
    Category: 'Wife',
  },
  {
    Code: 4,
    Description: 'Husband',
    Category: 'Husband',
  },
  {
    Code: 5,
    Description: 'Son',
    Category: 'Son',
  },
  {
    Code: 6,
    Description: 'Daughter',
    Category: 'Daughter',
  },
  {
    Code: 7,
    Description: 'Brother',
    Category: 'Brother',
  },
  {
    Code: 8,
    Description: 'Sister',
    Category: 'Sister',
  },
  {
    Code: 9,
    Description: 'Other',
    Category: 'Other',
  },
];
export const donationTime = [
  {
    Code: 1,
    Description: 'Breakfast',
    Category: 'Breakfast',
  },
  {
    Code: 2,
    Description: 'Lunch',
    Category: 'Lunch',
  },
  {
    Code: 3,
    Description: 'Dinner',
    Category: 'Dinner',
  },
  {
    Code: 4,
    Description: 'Evening',
    Category: 'Evening',
  },
  {
    Code: 5,
    Description: 'Snacks',
    Category: 'Snacks',
  },
  {
    Code: 6,
    Description: 'Full Day',
    Category: 'Full Day',
  },
];

export const PaymentType: any = [
  {
    Code: 1,
    Description: 'Cash',
    Category: 'Cash',
  },
  {
    Code: 2,
    Description: 'Material',
    Category: 'Kumar',
  },
  {
    Code: 3,
    Description: 'Cheque',
    Category: 'Cheque',
  },
  {
    Code: 4,
    Description: 'Online',
    Category: 'Online',
  },
];
export const paymentMethod: any = [
  {
    Code: 1,
    Description: 'Cash',
    Category: 'Cash',
  },
  {
    Code: 2,
    Description: 'Cheque',
    Category: 'Cheque',
  },
  {
    Code: 3,
    Description: 'Online',
    Category: 'Online',
  },
];
export const yourBank: any = [
  {
    Code: 1,
    Description: 'Indian Bank',
    Category: 'Indian Bank',
  },
  {
    Code: 2,
    Description: 'ICICI Bank',
    Category: 'ICICI Bank',
  },
];
export const DonateTypePayload: any = [
  {
    Code: 1,
    Description: 'Cash',
    Category: 'Cash',
  },
  {
    Code: 2,
    Description: 'Material',
    Category: 'Material',
  },
];
// export default { bloodGroup, gender };
