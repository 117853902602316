import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import CommonModal from './CommonModal';
import { ReactComponent as ErrorIcon } from '../../common/icons/Red_Alert_icon.svg';
import { ReactComponent as TickIcon } from '../../common/icons/Big-Green-Tick.svg';
import Spinner from './Spinner';
import CustomButton from '../../common/ui/CustomButton';
import { selectAlertInfo, setAlertInfo, dismissAlert } from '../Common.slice';

import SvgConverter from '../SvgConverter';
const ICONS: any = {
	SUCCESS: TickIcon,
	FAILED: ErrorIcon,
	ERROR: ErrorIcon,
	INFO: ErrorIcon,
	PROGRESS: ErrorIcon,
};

const ApiModal = () => {
	const dispatch = useDispatch();
	const alertInfo: any = useSelector(selectAlertInfo);
	console.log('alertInfo', alertInfo);

	const handleClickOnOk = () => {
		dispatch(setAlertInfo({ open: false } as any));
	};

	return (
		<CommonModal modalClassName="api-modal-container" open={alertInfo.open}>
			<Box>
				{alertInfo.type === 'PROGRESS' ? (
					<Grid
						display="flex"
						justifyContent="center"
						flexDirection="column"
						alignItems="center"
					>
						<Spinner />
					</Grid>
				) : (
					<Grid container alignItems="center" justifyContent="center">
						<Grid item marginBottom={1}>
							<Grid container alignItems="center" justifyContent="center">
								<Grid item marginBottom={1}>
									<SvgConverter Icon={ICONS[alertInfo?.type]} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
				{typeof alertInfo.description !== 'object' ? (
					<Typography textAlign="center" className="f-14 mb-15 mt-8">
						{alertInfo.description}
						{alertInfo.type === 'ERROR' && alertInfo.errorFields && (
							<div>
								{Object.entries(alertInfo.errorFields).map(([key, value]) => (
									<div key={key}>
										<Typography>
											{`${key}: ${typeof value === 'string' ? value : ''}`}
										</Typography>
									</div>
								))}
							</div>
						)}
					</Typography>
				) : (
					<>
						<div>
							{alertInfo.description?.map(
								({ primaryValue, errorValue, successValue }: any) => (
									<div
										key={primaryValue}
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
											marginBottom: 10,
											justifyContent: 'space-evenly',
										}}
									>
										<div>
											<Typography>
												{primaryValue}
												<span style={{ margin: '0 8px' }}>-</span>
											</Typography>
										</div>
										<div>
											<Typography>{errorValue || successValue}</Typography>
										</div>
									</div>
								)
							)}
						</div>

						{alertInfo.type === 'ERROR' && alertInfo.errorFields && (
							<div>
								{Object.entries(alertInfo.errorFields).map(([key, value]) => (
									<div key={key}>
										<Typography>
											{`${key}: ${typeof value === 'string' ? value : ''}`}
										</Typography>
									</div>
								))}
							</div>
						)}
					</>
				)}

				<Grid container alignItems="center" justifyContent="center">
					<Grid item>
						{alertInfo?.description !==
							'Please wait while document is downloading.' && (
							<CustomButton
								text="OK"
								showIcon={false}
								variant="outlined"
								color="success"
								onClick={handleClickOnOk}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</CommonModal>
	);
};

export default ApiModal;
