import {
	FormControl,
	Grid,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';

import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import CustomRadioGroup from '../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
type FormData = {
	selectedDate: Date | null;
	// Add other form fields here
};
const AddCompensatory = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedDate, setSelectedDate] = useState('');
	const [error, setError] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			selectedDate: null,
			purpose: location?.state?.data?.purpose || '',
		},
	});
	const handleReset = () => {
		setValue('selectedDate', null);
		setValue('purpose', '');
	};

	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};
	}, []);

	const { teamMember } = useSelector((state: any) => state);

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		navigate('/compensatory-leave');
		// navigate(APP_ROUTES.TEAM_MEMBER);
	};

	const handleDateChange = (event: any) => {
		const inputDate = event.target.value;
		console.log('eveeee', event, inputDate);
		const datePattern = /^\d{2}-\d{2}-\d{4}$/;
		console.log(
			'!datePattern.test(inputDate)',
			!datePattern.test(inputDate),
			inputDate
		);
		if (!datePattern.test(inputDate)) {
			setError(true);
		} else {
			setError(false);
			console.log('inputDate', inputDate);
			setSelectedDate(inputDate);
		}
	};
	const languageList = [
		{ value: 'full_day', label: 'Full day' },
		{ value: 'half_day', label: 'Half day' },
		,
	];
	const [selectedOption, setSelectedOption] = React.useState<any>('full_day');
	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};

	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					// justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.common.black}
						>
							{t('ADD_COMPENSATORY_LEAVE')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.COMPENSATORY_LEAVE);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={`${t('COMPENSATORY_OFF_ON_DATE')}`}
							labelProps={{ color: 'black' }}
						/>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={watch('selectedDate')}
									onChange={(date: any) => setValue('selectedDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('selectedDate', {
												required: 'To Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.selectedDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.selectedDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						p={2}
						className="mt-10"
					>
						<CustomRadioGroup
							name="RadioGroup"
							options={languageList}
							value={selectedOption}
							onChange={handleOptionChange}
							size="small"
							inputClassName="my-10 ml-10"
						/>
					</Grid>{' '}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('ENTER_PURPOSE')}
							labelProps={{ color: 'black' }}
							justifyContent="flex-start"
						/>
						<FormInput
							hideCountButton
							multipleLine
							name="purpose"
							type="text"
							placeholder={`${t('ENTER_PURPOSE')}...`}
							control={control}
							className="input-from-text my-4"
							inputProps={{
								...register('purpose', {}),
							}}
							errors={errors}
							rules={{
								required: {
									value: true,
									message: `${t('PURPOSE_IS_REQUIRED')}`,
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={`${t('RESET')}`}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text="ADD"
							color={'warning'}
							className="p-12 secondary-button"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AddCompensatory;
