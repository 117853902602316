import { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import PrintTable from './PrintTable';
import { DataGrid, GridClasses, GridColDef } from '@mui/x-data-grid';
import CustomButton from './CustomButton';
import { exportToCsv } from '../utils';
import './Table.scss';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useDemoData } from '@mui/x-data-grid-generator';
import { Box, Grid } from '@mui/material';
const TableComponent = ({
	rows,
	columns,
	type,
	view,
	add,
	loading = false,
	hideFooterPagination,
	className,
	totalRecords,
	handlePagination,
	paginationModel,
}: {
	rows: any[];
	columns: GridColDef[];
	hideFooterPagination?: any;
	className?: any;
	pagination?: any;
	type?: any;
	view?: any;
	add?: any;
	loading?: boolean;
	totalRecords?: any;
	handlePagination?: any;
	paginationModel?: any;
}) => {
	const [uploadOpen, setUploadOpen] = useState(false);
	const orgName = '';
	const generateCsvContent = (data: any, columns: any) => {
		// Extracting CSV headers
		const csvHeaders = columns.map((column: any) => column.title).join(',');

		// Extracting CSV data
		const csvData = data
			.map((row: any) =>
				columns.map((column: any) => row[column.dataIndex]).join(',')
			)
			.join('\n');

		// Creating CSV content
		return `${csvHeaders}\n${csvData}`;
	};

	const handleCsv = () => {
		const columnsToIncludePetition = [
			{ title: 'Full Name', dataIndex: 'full_name' },
			{ title: 'Email', dataIndex: 'email' },
			{ title: 'Mobile', dataIndex: 'mobile' },
			{ title: 'Description', dataIndex: 'description' },
			{ title: 'Subject', dataIndex: 'subject' },
			{
				title: 'Parliament subconstituencies Name',
				dataIndex: 'Parliamentsubconstituencies_name',
			},
		];
		const columnsToIncludeCandidate = [
			{ title: 'Full Name', dataIndex: 'full_name' },
			{ title: 'Email', dataIndex: 'email' },
			{ title: 'Mobile', dataIndex: 'mobile' },
			{ title: 'Address', dataIndex: 'address' },
		];
		const columnsToIncludeParty = [
			{ title: 'Name', dataIndex: 'name' },
			{ title: 'Image', dataIndex: 'image' },
		];
		const columnsToIncludePublic = [
			{ title: 'Full Name', dataIndex: 'full_name' },
			{ title: 'Email', dataIndex: 'email' },
			{ title: 'Mobile', dataIndex: 'contact' },
			{ title: 'Address', dataIndex: 'address' },
		];
		if (type === 'petition') {
			const csvContent = generateCsvContent(rows, columnsToIncludePetition);
			exportToCsv(csvContent, 'public-petition.csv');
		}
		if (type === 'candidate') {
			const csvContent = generateCsvContent(rows, columnsToIncludeCandidate);
			exportToCsv(csvContent, 'public-petition.csv');
		}
		if (type === 'party') {
			const csvContent = generateCsvContent(rows, columnsToIncludeParty);
			exportToCsv(csvContent, 'public-petition.csv');
		}
		if (type === 'public') {
			const csvContent = generateCsvContent(rows, columnsToIncludePublic);
			exportToCsv(csvContent, 'public-petition.csv');
		}
	};
	const handlePrint = () => {
		const selectedColumn = columns
			.filter(
				(column: any) =>
					column.headerName !== 'Options' && column.headerName !== 'Action'
			)
			.map((column: any) => ({
				title: column.headerName,
				dataIndex: column.field,
				key: column.key,
			}));
		console.log('selectedColumn', selectedColumn, columns);
		const newWindow = window.open('', '_blank');
		if (newWindow) {
			newWindow.document.write('<html><head><title>Print</title>');
			newWindow.document.write(
				"<link rel='stylesheet'  href='../../styles/modules/doctor.less'>"
			);
			newWindow.document.write('</head><body>');
			newWindow.document.write("<div id='print-content'>");
			ReactDOM.render(
				<PrintTable
					dataSource={rows}
					columns={selectedColumn}
					orgName={orgName}
				/>,
				newWindow.document.getElementById('print-content')
			);

			newWindow.document.write('</div>');
			newWindow.document.write('</body></html>');
			newWindow.document.close();
			newWindow.onload = () => {
				newWindow.print();
			};
		} else {
			console.error('Failed to open new window for printing.');
		}
	};
	const handlePdf = () => {
		const unit = 'pt';
		const size = 'A4';
		const orientation = 'landscape';
		const marginLeft = 40;
		const doc: any = new jsPDF(orientation, unit, size);
		doc.setFontSize(15);
		const title = 'Details';
		const defaultColumnWidth = 60;
		let columnsToRemove = [
			'createdAt',
			'updatedAt',
			'familyMember',
			'donationTime',
			'permissions',
			'Doner_Id',
			'donatedBy_id',
			'additionalInstructions',
			'additionalInstructionsMaterial',
			'additionalInstructionsGeneral',
			'recurringDonation',
			'donerBank',
			'Doner_serial_no',
			'Doner_address',
			'paymentNow',
			'user_id',
			'role_id',
			'password',
			'Action',
		];
		const cleanedDataSource = rows.map((obj: any) => {
			Object.values(obj).forEach((key) => {
				//  return key;
			});
			Object.keys(obj).forEach((key) => {
				if (
					columnsToRemove.includes(key) ||
					key.toLowerCase().startsWith('id')
				) {
					delete obj[key];
				}
			});
			return obj;
		});
		const dataSourceKeys = Object.keys(cleanedDataSource[0]);
		const headers = [dataSourceKeys.map((key) => key)];
		const dataValues = cleanedDataSource.map((row: any) => {
			const rowData: any = [];
			headers[0].forEach((header) => {
				rowData.push(row[header]);
			});
			return rowData;
		});
		const totalColumns = headers?.length;
		const columnStyles: any = {};
		for (let i = 0; i < totalColumns; i++) {
			columnStyles[i] = { columnWidth: defaultColumnWidth };
		}
		const content: any = {
			startY: 50,
			head: headers,
			body: dataValues,
			columnStyles: columnStyles,
		};
		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save('sivaPakkiyam.pdf');
	};
	const handleUpload = () => {
		setUploadOpen(true);
	};
	const customClasses: Partial<GridClasses> = {
		root: 'custom-root-class',
		columnHeader: 'custom-column-header-class',
	};
	const { data } = useDemoData({
		dataSet: 'Commodity',
		rowLength: 500,
		maxColumns: 6,
	});
	return (
		<>
			<Grid container>
				<Grid item xs={12} alignItems={'center'}>
					{type !== 'dashboard' && (
						<Grid
							xs={12}
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							pb={5}
						>
							<Grid xs={12} justifyContent={'center'} display={'flex'}></Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Box style={{ width: '100%' }}>
				<DataGrid
					rows={rows}
					columns={columns}
					rowCount={totalRecords}
					paginationMode="server"
					onPaginationModelChange={(e) => {
						handlePagination(e);
					}}
					paginationModel={paginationModel}
					// className={`${className} table-class`}
					classes={customClasses}
				/>
			</Box>
		</>
	);
};

export default TableComponent;
