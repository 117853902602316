import {
	FormControl,
	Grid,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';

import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
type FormData = {
	selectedDate: Date | null;
	// Add other form fields here
};
const AddShortLeaveForm = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedDate, setSelectedDate] = useState('');
	const [error, setError] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			phone: location.state?.data?.contact || '',
			selectedDate: null,
			toTime: null,
			fromTime: null,
			attendanceTime: null,
			purpose: location?.state?.data?.purpose || '',
			report_manager: location?.state?.data?.report_manager || '',
		},
	});
	const handleReset = () => {
		setValue('phone', '');
		setValue('selectedDate', null);
		setValue('toTime', null);
		setValue('fromTime', null);
		setValue('attendanceTime', null);
		setValue('purpose', '');
		setValue('report_manager', '');
	};

	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};
	}, []);

	const { teamMember } = useSelector((state: any) => state);

	const onSubmit = (data: any) => {
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		navigate('/short-leave');
		// navigate(APP_ROUTES.TEAM_MEMBER);
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	const handleDateChange = (event: any) => {
		const inputDate = event.target.value;
		console.log('eveeee', event, inputDate);
		const datePattern = /^\d{2}-\d{2}-\d{4}$/;
		console.log(
			'!datePattern.test(inputDate)',
			!datePattern.test(inputDate),
			inputDate
		);
		if (!datePattern.test(inputDate)) {
			setError(true);
		} else {
			setError(false);
			console.log('inputDate', inputDate);
			setSelectedDate(inputDate);
		}
	};

	return (
		<Grid>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					// justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.common.black}
						>
							{t('ADD_SHORT_LEAVE')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 secondary-button"
							onClick={() => {
								navigate(APP_ROUTES.SHORT_LEAVE);
							}}
						/>
					</Grid>{' '}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={`${t('FROM_DATE')}`}
							labelProps={{ color: 'black' }}
						/>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									// value={getValues('selectedDate')}
									value={watch('selectedDate')}
									onChange={(date: any) => setValue('selectedDate', date)}
									slotProps={{
										textField: {
											size: 'small',
											...register('selectedDate', {
												required: 'To Date is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.selectedDate && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.selectedDate?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>{' '}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={`${t('ATTENDANCE_TIME')}`}
							labelProps={{ color: 'black' }}
						/>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									// value={getValues('attendanceTime')}
									value={watch('attendanceTime')}
									onChange={(time: any) => setValue('attendanceTime', time)}
									slotProps={{
										textField: {
											size: 'small',
											...register('attendanceTime', {
												required: 'To time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.attendanceTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.attendanceTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('CONTACT_NUMBER_WHILE_ON_SHORT_LEAVE')}
							justifyContent="flex-start"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="phone"
							type="text"
							style={{ width: 100 }}
							placeholder={`${t('MOBILE_NUMBER')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
								},
								maxLength: {
									value: 10,
									message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
								},
								pattern: {
									value: /^[0-9]*$/,
									message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
								},
								minLength: {
									value: 10,
									message: `${t(
										'MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'
									)}`,
								},
							}}
							inputProps={{
								...register('phone', {}),
							}}
							errors={errors}
						/>
					</Grid>{' '}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('REPORTING_MANAGER')}
							justifyContent="flex-start"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="report_manager"
							type="text"
							placeholder={`${t('REPORT_MANAGER')}...`}
							control={control}
							// className="input-from-text"
							inputProps={{
								...register('report_manager', {}),
							}}
							errors={errors}
							rules={{
								required: {
									value: true,
									message: `${t('REPORT_MANAGER_IS_REQUIRED')}`,
								},
							}}
						/>
					</Grid>{' '}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('FROM_TIME')}
							justifyContent="flex-start"
							labelProps={{ color: 'black' }}
						/>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									// value={getValues('fromTime')}
									value={watch('fromTime')}
									onChange={(time: any) => setValue('fromTime', time)}
									slotProps={{
										textField: {
											size: 'small',
											...register('fromTime', {
												required: 'From time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.fromTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.fromTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('TO_TIME')}
							justifyContent="flex-start"
							labelProps={{ color: 'black' }}
						/>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									// value={getValues('toTime')}
									value={watch('toTime')}
									onChange={(time: any) => setValue('toTime', time)}
									slotProps={{
										textField: {
											size: 'small',
											...register('toTime', {
												required: 'To time is required',
											}),
										},
									}}
								/>
							</LocalizationProvider>
							{errors?.toTime && (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{errors?.toTime?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('PURPOSE/PLACE')}
							labelProps={{ color: 'black' }}
							justifyContent="flex-start"
						/>
						<FormInput
							hideCountButton
							multipleLine
							name="purpose"
							type="text"
							placeholder={`${t('RPURPOSE/PLACE')}...`}
							control={control}
							className="input-from-text"
							inputProps={{
								...register('purpose', {}),
							}}
							errors={errors}
							rules={{
								required: {
									value: true,
									message: `${t('PURPOSE_IS_REQUIRED')}`,
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={`${t('Reset')}`}
							color={'primary'}
							className="p-12 mx-10 primary-button"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text="ADD"
							color={'warning'}
							className="p-12 secondary-button"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AddShortLeaveForm;
