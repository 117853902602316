import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
	HandleAxiosRequest,
	HandleAxiosResponse,
	HandleAxiosResponseWithError,
} from './interceptors';
// TODO: add headers
const selectedLang = localStorage.getItem('i18nextLng');
const token = localStorage.getItem('accessToken');
const axiosAuthConfig: AxiosRequestConfig = {
	baseURL: `http://3.111.68.56:4000`,
	headers: {
		'content-type': 'application/x-www-form-urlencoded',
	},
	method: 'POST',
};
const axiosConfig: AxiosRequestConfig = {
	baseURL: `http://3.111.68.56:4000`,
	headers: {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	},
	method: 'POST',
};
const candidateInstance: AxiosRequestConfig = {
	baseURL: `http://3.111.68.56:4000`,
}
const Instance: any = axios.create({
	baseURL: `http://3.111.68.56:4000`,

})

const getInstance: any = axios.create({
	baseURL: `http://3.111.68.56:4000`,
	headers: {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	},
});
const axiosAuthInstance: AxiosInstance = axios.create(axiosAuthConfig);
const axiosInstance: AxiosInstance = axios.create(axiosConfig);
const getInstances: AxiosInstance = axios.create(getInstance);
const candidateInstances: AxiosInstance = axios.create(candidateInstance);
axiosInstance.interceptors.request.use(HandleAxiosRequest, (error) =>
	// Do something with request error
	Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	HandleAxiosResponse,
	HandleAxiosResponseWithError
);
candidateInstances.interceptors.request.use(HandleAxiosRequest, (error) =>
	// Do something with request error
	Promise.reject(error)
);
candidateInstances.interceptors.response.use(
	HandleAxiosResponse,
	HandleAxiosResponseWithError
);
export { axiosAuthInstance, axiosInstance, candidateInstances, getInstance ,candidateInstance,Instance};
