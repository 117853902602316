import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation } from 'react-router-dom';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import { findIndex } from 'lodash';

import LeftMenuList from './components/LeftMenuList';
import AppFooter from './components/AppFooter';
import { APP_ROUTES, AUTH_ROUTES } from '../common/constants/Routes';
import TopHeader from './components/TopHeader';
import TopMenu from './components/TopMenu';
const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop: any) => prop !== 'open',
})<AppBarProps>(({ theme, open }: any) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const AppLayout = () => {
	const theme = useTheme();
	const appBarRef = React.useRef<HTMLDivElement>(null);
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [contentHeight, setContentHeight] = React.useState(20);
	const [notificationDrawer, setNotificationDrawer] = React.useState(false);
	const location = useLocation();
	console.log(
		'applayout',

		findIndex(
			[AUTH_ROUTES.LOGIN],
			(val: any) => val === (location.pathname === '' ? '/' : location.pathname)
		) !== -1
	);
	React.useEffect(() => {
		if (appBarRef.current) {
			setContentHeight(20 + 20);
		}
	}, [appBarRef, location?.pathname]);

	const isMobile = useMediaQuery('(max-width:700px)');
	return (
		<Box className="app-layout-container">
			<CssBaseline />
			<AppBar
				ref={appBarRef}
				position="sticky"
				sx={{ backgroundColor: theme.palette.common.white }}
				elevation={0}
			>
				<Toolbar className="p-0  tool-bar-container">
					{/* {findIndex(
						[APP_ROUTES.DASHBOARD, APP_ROUTES.CANDIDATE],
						(val: any) =>
							val === (location.pathname === '' ? '/' : location.pathname)
					) !== -1 ? ( */}
					<TopHeader
						setDrawerOpen={setDrawerOpen}
						drawerOpen={drawerOpen}
						contentHeight={contentHeight}
						setNotificationDrawer={setNotificationDrawer}
					/>
					{/* ) : (
						''
						// <BreadCrumps />
					)} */}
				</Toolbar>
			</AppBar>
			<Box component="main" className="px-20 py-15">
				<Outlet />
			</Box>{' '}
			{findIndex(
				[AUTH_ROUTES.LOGIN],
				(val: any) =>
					val === (location.pathname === '' ? '/' : location.pathname)
			) !== -1 ? (
				// <AppFooter />
				<></>
			) : (
				''
			)}
			<SwipeableDrawer
				className="notification-drawer-container"
				anchor="left"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				onOpen={() => setDrawerOpen(true)}
			>
				<LeftMenuList setOpen={setDrawerOpen} />
			</SwipeableDrawer>
			{/* <SwipeableDrawer
        className='notification-drawer-container'
        anchor='right'
        open={notificationDrawer}
        onClose={() => setNotificationDrawer(false)}
        onOpen={() => setNotificationDrawer(true)}
      ></SwipeableDrawer> */}
		</Box>
	);
};

export default AppLayout;
