import { Grid, Typography, useTheme } from '@mui/material';

import { useForm, useWatch } from 'react-hook-form';
import React, { useState } from 'react';

import './Login.scss';

import image from '../../common/icons/image3 1.png';
import LabelWithIcon from '../../common/ui/LabelWithIcon';
import FormInput from '../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../common/ui/CustomButton';
import { AUTH_ROUTES } from '../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { forgetPasswordRequest } from '../../store/auth/_auth.slice';
import { useDispatch } from 'react-redux';
const ForgetPassword = () => {
	const { t } = useTranslation();
	const theme = useTheme();
const dispatch=useDispatch()
	const [password, setPassword] = useState(true);
	const navigate = useNavigate();
	const location= useLocation()
	const [passwordMatch, setPasswordMatch] = useState(true);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		getValues
	} = useForm({
		defaultValues: {
			password: '',
			ConfirmPassword: '',
		},
	});
 const onSubmit = (data:any) => {
    console.log("data")
    if(passwordMatch){
      const handleSuccess=()=>{
        navigate(AUTH_ROUTES.LOGIN)
      }
      data.email=location.state
      const payload ={
        data,
        handleSuccess
  
      }
    dispatch(forgetPasswordRequest(payload))
    }
}
	const handleChange = (e:any) => {
		const { name, value } = e.target;
		const newPassword = getValues('password');
		if (name === 'ConfirmPassword' && newPassword !== value) {
		  setPasswordMatch(false);
		} else {
		  setPasswordMatch(true);
		}
	  };

	return (
		<Grid className='forget-password-form'>
	
	
			  <form   onSubmit={handleSubmit(onSubmit)}>
          <Grid >
          <Grid >
              <LabelWithIcon
              	label={t('NEW_PASSWORD')}
                labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
               placeholder={`${t('ENTER_NEW_PASSWORD')}...`}
                className='username-input'
                name='password'
                type='password'
                control={control}
                rules={{
                    required: t('PLEASE_ENTER_NEW_PASSWORD'),
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('password'),
                  onChange: handleChange
                }}
                errors={errors}
              />
            </Grid>
            <Grid my={1}>
              <LabelWithIcon
              	label={t('CONFIRM_NEW_PASSWORD')}
                labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                  placeholder={`${t('CONFIRM_YOUR_PASSWORD')}...`}
                className='username-input'
                name='confirmPassword'
                type='password'
                control={control}
                rules={{
					required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('ConfirmPassword'),
                  onChange: handleChange
                }}
                errors={errors}
              />
              {!passwordMatch && <Typography color={"red"}>{`${t('PASSWORD_NOT_MATCH')}`}</Typography>}
            </Grid>

            <Grid justifyContent='center' display={'flex'} my={5}>
              <CustomButton
                type='submit'
                text='Submit'
                // width={200}
                className="login-button"
              //  className='forget-password-button'
                disabled={!passwordMatch}
              />
            </Grid>
          </Grid>
        </form>
		</Grid>
	);
};

export default ForgetPassword;
