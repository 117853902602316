/* eslint-disable no-restricted-syntax */
import { createTheme } from '@mui/material';

// const theme = createTheme({ palette: { primary: { dark: '#1565c0' } as any } });
const theme = createTheme({
	palette: {
		mode: 'light',
		common: {
			black: '#000',
			white: '#fff',
		},
		primary: {
			main: '#EC2A90', //pink
			light: '#42a5f5',
			dark: '#1565c0',
			contrastText: '#fff',
		},
		secondary: {
			main: '#122857', //blue
			light: '#ba68c8',
			dark: '#7b1fa2',
			contrastText: '#fff',
		},
		error: {
			main: '#d32f2f',
			light: '#ef5350',
			dark: '#d9001b',
			contrastText: '#fff',
		},
		warning: {
			main: '#ed6c02',
			light: '#ff9800',
			dark: '#e65100',
			contrastText: '#fff',
		},
		info: {
			main: '#0288d1',
			light: '#03a9f4',
			dark: '#01579b',
			contrastText: '#fff',
		},
		success: {
			main: '#63a103',
			light: '#4caf50',
			dark: '#1b5e20',
			contrastText: '#fff',
		},
		grey: {
			'50': '#fafafa',
			'100': '#f5f5f5',
			'200': '#eeeeee',
			'300': '#e0e0e0',
			'400': '#aaaaaa',
			'500': '#9e9e9e',
			'600': '#757575',
			'700': '#616161',
			'800': '#424242',
			'900': '#212121',
			A100: '#f5f5f5',
			A200: '#eeeeee',
			A400: 'rgba(255, 255, 255, 0.45)',
			A700: '#7f7f7f',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: '#000',
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		divider: 'rgba(255, 255, 255, 0.24)',

		background: {
			paper: '#fff',
			default: '#fff',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.04)',
			hoverOpacity: 0.04,
			selected: 'rgba(0, 0, 0, 0.08)',
			selectedOpacity: 0.08,
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(0, 0, 0, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.12,
		},
	},
});

export default theme;

export const changeCssColors = (theme: any) => {
	if (theme) {
		const variables: any = {
			'primary-main': theme.palette.primary.main,
			'secondary-main': theme.palette.secondary.main,
			'grey-separator': theme.palette.grey[400],
			'black-main': theme.palette.common.black,
			'white-main': theme.palette.common.white,
		};

		for (const key in variables) {
			if (Object.prototype.hasOwnProperty.call(variables, key)) {
				document.documentElement.style.setProperty(`--${key}`, variables[key]);
			}
		}
	}
};
