import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';
import ShortLeaveHeader from './ShortLeaveHeader';

function ShortLeaveTable() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState([]);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePageChange = (page: any) => {};

	const handleDelete = () => {
		setOpen(true);
	};
	const handleDeleteAndSetId = (id: any) => {
		handleDelete();
		setId(id);
	};
	const columns: any = [
		{
			headerName: 'Date',
			field: 'date',
			width: 200,
		},
		{
			headerName: 'Attendance Time',
			field: 'attendance_time',
			width: 250,
		},
		{
			headerName: 'Contact Number',
			field: 'contact_number',
			width: 300,
		},
		{
			headerName: 'From Time',
			field: 'from_time',
			width: 300,
		},
		{
			headerName: 'To Time',
			field: 'to_time',
			width: 300,
		},
		{
			headerName: 'Purpose',
			field: 'purpose',
			width: 300,
		},
	];
	const rows: any = [
		{
			id: 1,
			date: '2023-12-10',
			attendance_time: '10:00 AM',
			contact_number: '90877676666',
			from_time: '11:00 AM',
			to_time: '2:00 PM',
			purpose: 'medical emegency',
		},
		{
			id: 2,
			date: '2023-12-10',
			attendance_time: '10:00 AM',
			contact_number: '90877676666',
			from_time: '11:00 AM',
			to_time: '2:00 PM',
			purpose: 'medical emegency',
		},
		{
			id: 3,
			date: '2023-12-10',
			attendance_time: '10:00 AM',
			contact_number: '90877676666',
			from_time: '11:00 AM',
			to_time: '2:00 PM',
			purpose: 'medical emegency',
		},
	];

	return (
		<Grid container>
			<Grid xs={12} mb={5}>
				<ShortLeaveHeader />
			</Grid>
			<Grid
				item
				container
				display="flex"
				alignItems={'center'}
				mb={2}
				justifyContent={'space-around'}
				// className='login-form'
				spacing={1}
			>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Req.No:</Typography>
					<TextField
						label={'Req.no'}
						size="small"
						variant="outlined"
						//   value={searchQuery}
						//   onChange={handleSearchChange}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Applied Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid
					item
					xs={10}
					sm={7}
					md={12}
					lg={3}
					xl={3}
					display={'flex'}
					spacing={2}
					container
				>
					<Grid item>
						<CustomButton
							type="button"
							text={t('SEARCH')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>{' '}
					<Grid item>
						<CustomButton
							type="button"
							text={t('EXPORT_TO_EXCEL')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					totalRecords={rows.length}
					handlePagination={handlePaginationChange}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>
		</Grid>
	);
}
export default ShortLeaveTable;
