import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import './Login.scss';
import Slider from 'react-slick';
import ForgetPassword from './ForgetPassword';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Login.scss';
import person from '../../common/icons/40.png';
import Person2Icon from '../../common/icons/80 (1).png';
const ForgetPasswordScreen = () => {
	const theme = useTheme();
	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, 
		autoplaySpeed: 200,
		nextArrow: <></> 
	  };
	return (
	
		<Grid
		item
	
		justifyContent="center"
		display={'flex'}
		alignItems={'center'}
		flexDirection={"column"}
	
		
	>
		<div className='login-logo'>
		<img src={Person2Icon} alt="logo" className="img-logo-login" />
		</div>

		<ForgetPassword />
	</Grid>
	);
};
export default ForgetPasswordScreen;
