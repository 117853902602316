import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import '../dashboard.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDispatch } from 'react-redux';

import TableComponent from '../../../common/ui/TableComponent';

// import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomButton from '../../../common/ui/CustomButton';

function TravelExpensesCancellationTable() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState([]);
	const [open, setOpen] = useState(false);
	const [Succesopen, setSuccesopen] = useState(false);
	const [id, setId] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const handlePaginationChange = (paginationModel: any) => {
		const { page, pageSize } = paginationModel;

		setCurrentPage(page);
		setPageSize(pageSize);
	};
	const success = () => {
		setSuccesopen(true);
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch( getTeamMemberRolesListInProgress(paylod))
	}, []);

	// console.log("teamMember",teamMemberData)
	// console.log("id",id)
	const handlePageChange = (page: any) => {};

	const handleDelete = () => {
		setOpen(true);
	};
	const handleDeleteAndSetId = (id: any) => {
		handleDelete();
		setId(id);
	};
	const columns: any = [
		{
			headerName: 'Req.No',
			field: 'req_no',
			width: 150,
		},
		{
			headerName: 'Tour Type',
			field: 'tour_type',
			width: 150,
		},
		{
			headerName: 'Requested Date',
			field: 'requested_date',
			width: 150,
		},
		{
			headerName: 'Tour From Date',
			field: 'tour_from_date',
			width: 100,
		},
		{
			headerName: 'Tour To Date',
			field: 'tour_to_date',
			width: 100,
		},
		{
			headerName: 'Cancellation RM Status',
			field: 'cancellation_rm_status',
			width: 150,
		},
		{
			headerName: 'Cancellation HOD Status',
			field: 'cancellation_hod_status',
			width: 150,
		},
		{
			headerName: 'Cancellation Regional Admin Status',
			field: 'cancellation_regional_admin_status',
			width: 250,
		},
		{
			headerName: 'Cancellation Finance Status',
			field: 'cancellation_finance_status',
			width: 250,
		},
		{
			headerName: 'Name',
			field: 'name',
			width: 200,
		},
	];
	const rows: any = [
		{
			id: 1,
			req_no: 'REQ0008',
			tour_type: 'Domestic',
			requested_date: '2/5/2024',
			tour_from_date: '6/5/2024',
			tour_to_date: '10/5/2024',
			cancellation_rm_status: 'Pending',
			cancellation_hod_status: 'NA',
			cancellation_regional_admin_status: 'NA',
			cancellation_finance_status: 'NA',
			name: 'Chandrapakrash',
		},
		{
			id: 2,
			req_no: 'REQ0008',
			tour_type: 'Domestic',
			requested_date: '2/5/2024',
			tour_from_date: '6/5/2024',
			tour_to_date: '10/5/2024',
			cancellation_rm_status: 'Pending',
			cancellation_hod_status: 'NA',
			cancellation_regional_admin_status: 'NA',
			cancellation_finance_status: 'NA',
			name: 'Chandrapakrash',
		},
		{
			id: 3,
			req_no: 'REQ0008',
			tour_type: 'Domestic',
			requested_date: '2/5/2024',
			tour_from_date: '6/5/2024',
			tour_to_date: '10/5/2024',
			cancellation_rm_status: 'Pending',
			cancellation_hod_status: 'NA',
			cancellation_regional_admin_status: 'NA',
			cancellation_finance_status: 'NA',
			name: 'Chandrapakrash',
		},
	];

	return (
		<Grid container>
			<Grid xs={12} mb={5} xl={12}>
				<Grid
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					className="donation-header"
				>
					<Typography fontSize={20} fontWeight={700}>
						Travel Expenses Cancellation
					</Typography>

					<Typography fontSize={20} fontWeight={700} className="mr-30">
						Total Records : 4
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				container
				display="flex"
				alignItems={'center'}
				mb={2}
				justifyContent={'space-around'}
				// className='login-form'
				spacing={1}
			>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">Req.No:</Typography>
					<TextField
						label={'Req.no'}
						size="small"
						variant="outlined"
						//   value={searchQuery}
						//   onChange={handleSearchChange}
						fullWidth
					/>
				</Grid>
				<Grid
					xs={6}
					sm={4}
					md={2}
					lg={1}
					xl={1}
					mb={3}
					className="my-5 align-center"
				>
					<Typography className="from-date-text ">Req.Date:</Typography>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">From Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2} xl={2} mb={3}>
					<Typography className="from-date-text">To Date:</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker slotProps={{ textField: { size: 'small' } }} />
					</LocalizationProvider>
				</Grid>{' '}
				<Grid
					item
					xs={10}
					sm={7}
					md={12}
					lg={3}
					xl={3}
					display={'flex'}
					spacing={2}
					container
				>
					<Grid item>
						<CustomButton
							type="button"
							text={t('SEARCH')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>{' '}
					<Grid item>
						<CustomButton
							type="button"
							text={t('EXPORT_TO_EXCEL')}
							className="px-5 py-3 primary-button"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid xs={12}>
				<TableComponent
					rows={rows}
					columns={columns}
					paginationModel={{ page: currentPage, pageSize: pageSize }}
					totalRecords={rows.length}
					handlePagination={handlePaginationChange}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}

					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>
		</Grid>
	);
}
export default TravelExpensesCancellationTable;
